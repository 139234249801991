import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionGroup, OverlayTrigger, Tooltip, ActionButton, Icon } from 'ui'
import { getCmdModifier } from '@services/engine/utils'
import { useCamera } from '@services/engine/useCamera'
import { EngineSelectedSceneNode, ScenePrimType } from '@services/engine/types'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import EditShape from '@components/propertiesPanel/EditShape'
import TransferMaterial from '@components/propertiesPanel/TransferMaterial'
import ObjectVisibilityToggle from './ObjectVisibilityToggle'
import ObjectDuplicateButton from './ObjectDuplicateButton'
import ObjectDeleteButton from './ObjectDeleteButton'
import FrameSelectionIcon from '/public/icon_frame_selection.svg'
import CopyMaterialIcon from '/public/temp-icon-material-copy.svg'
import FontPicker, {
  FontPropertiesType
} from '@components/fontPicker/FontPicker'
import { FontSizeField } from '@components/propertiesPanel/FontSizeField'
import FireflyShowGeneratePromptButton from '@components/fireflyPanel/FireflyShowGeneratePromptButton'
import { useSelection } from '@services/engine/useSelection'
import GroupIcon from '/public/s2_icon_group.svg'
import UngroupIcon from '/public/s2_icon_ungroup.svg'
import { PasteMaterial } from '@components/propertiesPanel/PasteMaterial'
import { useFirefly } from '@hooks/useFirefly'
import { useTranslations } from 'use-intl'
import { LocalesRequiringSpecialFonts } from '@concerns/i18n/constants'
import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'
import styles from '@styles/components/Textfield.module.scss'

const ObjectContextBar: FC = () => {
  const t = useTranslations()
  const { activeLocale } = useContext(LocaleContext)

  const flags = useFlags()

  const { copyMaterials, setPropertyState, changeFont } = useSceneActions()

  const selectedSceneNode = useSceneState('selectedSceneNode')
  const primitiveType = useSceneState('primitiveType')
  const { isSceneRealisticOrVectorArt } = useFirefly()

  const isTextObjectMode =
    primitiveType === ScenePrimType.TEXT &&
    selectedSceneNode === EngineSelectedSceneNode.SHAPE

  const is3DObjectMode =
    selectedSceneNode === EngineSelectedSceneNode.SHAPE &&
    primitiveType !== ScenePrimType.TEXT

  const isMulti3DObjectMode =
    selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED

  const shouldShowEditShapeButton =
    is3DObjectMode && flags['base-pf-ui-edit-shape-toggle']

  const { frameSelection } = useCamera()

  const { canGroup, group, canUngroup, ungroup } = useSelection()

  const groupButtonEnabled = canGroup()
  const ungroupButtonEnabled = canUngroup()

  const modifier = getCmdModifier()

  const size = useMediumMinimumSizePreference()

  const textCopy = useSceneState('textCopy')
  const selectedFontPostScriptName = useSceneState('selectedFontPostScriptName')

  const specialFontIsRequired = activeLocale in LocalesRequiringSpecialFonts
  const defaultFontIsSelected = selectedFontPostScriptName === ''

  const textInputRef = useRef<HTMLInputElement>(null)

  useLayoutEffect(() => {
    if (isTextObjectMode && textInputRef.current) {
      textInputRef.current.focus()
      textInputRef.current.select()
    }
  }, [isTextObjectMode])

  useEffect(() => {
    if (specialFontIsRequired && defaultFontIsSelected && isTextObjectMode) {
      changeFont(LocalesRequiringSpecialFonts[activeLocale]!)
      setPropertyState({
        key: 'textLeadingType',
        value: 1 // NOTE(JoshC): see neoElement.h/FontLeadingType for values
      })
    }
  }, [
    activeLocale,
    specialFontIsRequired,
    defaultFontIsSelected,
    isTextObjectMode
  ])

  return (
    <>
      {shouldShowEditShapeButton && <EditShape />}
      {isTextObjectMode && (
        <>
          <input
            className={styles['input']}
            ref={textInputRef}
            disabled={specialFontIsRequired && defaultFontIsSelected}
            style={{
              width: '100%',
              paddingTop: '4px',
              paddingBottom: '5px',
              paddingLeft: '7px',
              paddingRight: '7px'
            }}
            id="three-d-text-input"
            placeholder={t('object:properties:text:input:placeholder')}
            value={textCopy}
            onInput={e => {
              // Add in an end of line character where line breaks are
              const text = (e.target as HTMLInputElement).value
                .split('\n')
                .join('\n')

              setPropertyState({
                key: 'textCopy',
                value: text
              })
            }}
          />
          <FontPicker size={size} type={FontPropertiesType.FAMILIES} />
          <FontSizeField />
        </>
      )}

      {isMulti3DObjectMode && isSceneRealisticOrVectorArt && (
        <FireflyShowGeneratePromptButton />
      )}

      {groupButtonEnabled && (
        <OverlayTrigger placement="top" offset={0}>
          <ActionButton
            size={size}
            slot="trigger"
            onClick={() => {
              group()
              document.getElementById('canvas')?.focus()
            }}
          >
            <Icon slot="icon" className="icon-m">
              <GroupIcon />
            </Icon>
            {t('studio:contextBar:group')}
          </ActionButton>
          <Tooltip slot="hover-content">
            {t('studio:tooltips:group')} (
            {t(`studio:tooltips:groupKeybind:${modifier}`)})
          </Tooltip>
        </OverlayTrigger>
      )}

      {ungroupButtonEnabled && (
        <OverlayTrigger placement="top" offset={0}>
          <ActionButton
            size={size}
            slot="trigger"
            onClick={() => {
              ungroup()
              document.getElementById('canvas')?.focus()
            }}
          >
            <Icon slot="icon" className="icon-m">
              <UngroupIcon />
            </Icon>
            {t('studio:contextBar:ungroup')}
          </ActionButton>
          <Tooltip slot="hover-content">
            {t('studio:tooltips:ungroup')} (
            {t(`studio:tooltips:ungroupKeybind:${modifier}`)})
          </Tooltip>
        </OverlayTrigger>
      )}

      <ActionGroup quiet vertical={false} style={{ flexWrap: 'nowrap' }}>
        <ObjectDuplicateButton />
        <ObjectVisibilityToggle />
        <ObjectDeleteButton />

        <OverlayTrigger placement="top" offset={0}>
          <ActionButton
            size={size}
            quiet
            slot="trigger"
            onClick={() => {
              frameSelection()
              document.getElementById('canvas')?.focus()
            }}
          >
            <Icon slot="icon" className="icon-m">
              <FrameSelectionIcon />
            </Icon>
          </ActionButton>
          <Tooltip slot="hover-content">
            {t('studio:tooltips:frameObject')} (
            {t(`studio:tooltips:frameObjectKeybind:${modifier}`)})
          </Tooltip>
        </OverlayTrigger>

        {!isMulti3DObjectMode && (
          <>
            <OverlayTrigger placement="top" offset={0}>
              <ActionButton
                size={size}
                quiet
                slot="trigger"
                disabled={isMulti3DObjectMode}
                onClick={() => {
                  copyMaterials()
                  document.getElementById('canvas')?.focus()
                }}
              >
                <Icon slot="icon" className="icon-m">
                  <CopyMaterialIcon />
                </Icon>
              </ActionButton>
              <Tooltip slot="hover-content">
                {t('studio:tooltips:copyMaterial')}
              </Tooltip>
            </OverlayTrigger>
          </>
        )}

        <PasteMaterial />
        {isMulti3DObjectMode && <TransferMaterial />}
      </ActionGroup>
    </>
  )
}

export default ObjectContextBar
