import React from 'react'
import { Button, Icon } from 'ui'
import styles from '@styles/components/FireflyPaywallToast.module.scss'
import cn from 'classnames'
import { useFirefly } from '@hooks/useFirefly'
import { useTranslations } from 'use-intl'
import PremiumIcon from '/public/s2_icon_premium.svg'
import CrossIcon from '/public/s2_icon_cross_small.svg'
import { useAccessProfileState } from '@hooks/useAccessProfile'

function FireflyPaywallToast() {
  const t = useTranslations()

  const fireflyFulfillableItemCreditModel = useAccessProfileState(
    'fireflyFulfillableItemCreditModel'
  )

  const { showPaywallToast, setShowPaywallToast, setShowPaywall } = useFirefly()

  function handleClose() {
    setShowPaywallToast(false)
  }

  function handleUpgrade() {
    setShowPaywall('quota')
    setShowPaywallToast(false)
  }

  const ctaButton = (
    <Button
      className={cn('firefly-upgrade-button', styles['cta-button'])}
      onClick={handleUpgrade}
    >
      <Icon slot="icon">
        <PremiumIcon />
      </Icon>
      {t('studio:firefly:paywall:toast:cta-button')}
    </Button>
  )

  const closeButton = (
    <button className={styles['close-button']} onClick={handleClose}>
      <CrossIcon />
    </button>
  )

  return (
    <div
      className={cn(styles['container'], {
        [styles['container-visible']]: showPaywallToast
      })}
    >
      {fireflyFulfillableItemCreditModel === 'FREE-EXHAUSTABLE' ? (
        <div className={styles['free-exhaustable-content']}>
          <div className={styles['text-container']}>
            <p>{t('studio:firefly:paywall:toast:free-user:description')}</p>
          </div>
          {ctaButton}
          {closeButton}
        </div>
      ) : (
        <div className={styles['paid-exhaustable-content']}>
          <div className={styles['paid-exhaustable-content-left-side']}>
            <p>
              {t('studio:firefly:paywall:toast:paid-exhaustable:description')}
            </p>
            {ctaButton}
          </div>

          {closeButton}
        </div>
      )}
    </div>
  )
}

export default FireflyPaywallToast
