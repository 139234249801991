export type TrackingEvent =
  | 'discover:appBar:createProject'
  | 'landing:appBar:createProject'
  | 'landing:hero:getStarted'
  | 'landing:hero:seeHowItWorks'
  | 'studio:firefly:generate'
  | 'studio:firefly:generateSimilar'
  | 'studio:firefly:download'
  | 'studio:appBar:tryNeo'
  | 'studio:combinePanel:tryNeo'
  | 'studio:fireflyPanel:tryNeo'
  | 'studio:toNeoDialog:tryNeo'

export const mapCreateProjectButtonRouteToTrackingEventId = (
  route: string
): TrackingEvent | undefined => {
  if (route === '/discover') return 'discover:appBar:createProject'
  if (route === '/') return 'landing:appBar:createProject'
  return undefined
}
