import {
  AccessibleItemStatus,
  AccessProfile,
  AccessProfileResponse,
  Entitlement,
  ENTITLEMENT_BLOCK,
  FeatureKeys,
  FeatureSets,
  FireflyFulfillableItemCreditModel
} from './types'

// TODO: need to refactor this, so we are not hardcoding locale
const APP_DETAILS = {
  nglAppId: process.env.NEXT_PUBLIC_CLIENT_ACCESS_PROFILE_PROJECT_NEO_APP_ID,
  nglAppVersion: '1.0',
  nglLibRuntimeMode: 'NAMED_USER_ONLINE',
  locale: 'en-US'
}

async function fetchAccessProfile(
  accessToken: string
): Promise<AccessProfile | null> {
  try {
    const response = await fetch(
      process.env.NEXT_PUBLIC_CLIENT_ACCESS_PROFILE_ENDPOINT!,
      {
        method: 'POST',
        headers: {
          Authorization: accessToken,
          'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          appDetails: APP_DETAILS
        })
      }
    )

    const json: AccessProfileResponse = await response.json()
    const buffer = Buffer.from(json?.asnp?.payload, 'base64')
    const deserializedAccessProfile = buffer.toString('utf-8')
    
    return JSON.parse(deserializedAccessProfile)
  } catch (err) {
    console.error(err)
    
    return null
  }
}

function parseEntitlement(accessProfile: AccessProfile): Entitlement {
  const userEntitlement: Entitlement = Object.create(ENTITLEMENT_BLOCK)
  const accessibleItems = accessProfile?.appProfile?.accessibleItems ?? []

  accessibleItems.forEach(accessibleItem => {
    if (
      accessibleItem.status !== AccessibleItemStatus.ACTIVE ||
      !accessibleItem.fulfillable_items
    )
      return

    const featureSets =
      accessibleItem.fulfillable_items['firefly_credits']?.feature_sets

    if (featureSets) {
      userEntitlement.isFree =
        userEntitlement.isFree || !!featureSets[FeatureKeys.BASIC]?.enabled
      userEntitlement.isPaid =
        userEntitlement.isPaid || !!featureSets[FeatureKeys.PREMIUM]?.enabled
      userEntitlement.isHardCapped =
        userEntitlement.isHardCapped ||
        !!featureSets[FeatureKeys.HARD_CAPPED]?.enabled
      // In the absence of both hard and soft cap markers treat user as soft cap.
      // More details in https://jira.corp.adobe.com/browse/DVAGENAI-3248
      userEntitlement.isSoftCapped =
        userEntitlement.isSoftCapped ||
        !!featureSets[FeatureKeys.SOFT_CAPPED]?.enabled ||
        (featureSets[FeatureKeys.SOFT_CAPPED]?.enabled === undefined &&
          featureSets[FeatureKeys.HARD_CAPPED]?.enabled === undefined)
      userEntitlement.fireflyPlan = getFireflyPlan(featureSets)
    }
  })

  if (userEntitlement.isPaid) userEntitlement.isFree = false
  // If any value of isSoftCapped is true from above iteration then isHardCapped should be false.
  if (userEntitlement.isSoftCapped) userEntitlement.isHardCapped = false

  return userEntitlement
}

function getFireflyFulfillableItemCreditModelFromEntitlement(
  entitlement: Entitlement
): FireflyFulfillableItemCreditModel {
  if (!entitlement.isPaid && entitlement.isHardCapped) return 'FREE-EXHAUSTABLE'
  if (
    entitlement.isPaid &&
    entitlement.isHardCapped &&
    entitlement.fireflyPlan === 'FF_5'
  )
    return 'PAID-EXHAUSTABLE'
  if (
    entitlement.isSoftCapped ||
    (entitlement.isPaid &&
      entitlement.isHardCapped &&
      entitlement.fireflyPlan !== 'FF_5')
  )
    return 'PAID-UNEXHAUSTABLE'

  return 'UNAVAILABLE'
}

export async function getUserFireflyFulfillableItemCreditModel(
  accessToken: string
): Promise<FireflyFulfillableItemCreditModel | null> {
  const accessProfile = await fetchAccessProfile(accessToken)
  if (!accessProfile) return 'UNAVAILABLE'
  const entitlement = parseEntitlement(accessProfile)
  const fiCreditModel =
    getFireflyFulfillableItemCreditModelFromEntitlement(entitlement)

  return fiCreditModel
}

function getFireflyPlan(featureSets: FeatureSets) {
  if (featureSets[FeatureKeys.FIREFLY_PLAN_30]?.enabled) {
    return 'FF_30'
  } else if (featureSets[FeatureKeys.FIREFLY_PLAN_10]?.enabled) {
    return 'FF_10'
  } else if (
    featureSets[FeatureKeys.FIREFLY_PLAN_SINGLE_APP]?.enabled &&
    featureSets[FeatureKeys.HARD_CAPPED_LIMIT_2]?.enabled
  ) {
    return 'FF_5'
  } else if (featureSets[FeatureKeys.FIREFLY_PLAN_ALL_APP]?.enabled) {
    return 'FF_ALL'
  } else if (featureSets[FeatureKeys.FIREFLY_PLAN_SINGLE_APP]?.enabled) {
    return 'FF_SINGLE'
  } else if (featureSets[FeatureKeys.FIREFLY_PLAN_FREE]?.enabled) {
    return 'FF_FREE'
  }
  console.warn('No Firefly Plan found return FF_FREE')
  return null
}
