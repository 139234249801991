import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { EventChannel } from 'redux-saga'
import { PayloadType, RootState } from '@store/store'
import { EventPayload, UniversalNavContext } from '@services/universalNav/types'
import AdobeIMS from '@services/auth/IMS'
import Context from '../context'
import createEventChannel from '../createEventChannel'
import {
  setColorPreference,
  setShowSettingsDialog,
  setShowLegalNoticesDialog
} from '@store/slices/projectSlice'
import { logout } from '@store/slices/authSlice'
import { getUserLocalePreference } from '@concerns/i18n/browser'
import { setShowPaywall } from '@store/slices/fireflySlice'

type IMSProfile = {
  account_type: string
  userId: string
  email: string
}

export const loadUniversalNav =
  createAction<UniversalNavContext>('UniversalNav/load')

// handleLoadUniversalNav references stale AdobeIMS class if called via `yield call(AdobeIMS.getProfile)`
async function getProfile() {
  return AdobeIMS.getProfile()
}

function* handleLoadUniversalNav({
  payload: context
}: PayloadAction<UniversalNavContext>) {
  const {
    auth: { status, localUser },
    project
  }: RootState = yield select((state: RootState) => state)
  if (status !== 'AUTHENTICATED' || !localUser) return

  const profile: IMSProfile = yield call(getProfile)

  const tokenInfo = AdobeIMS.getAccessToken()
  if (!tokenInfo?.token) return

  Context.UniversalNav.configureUser({
    accessToken: tokenInfo.token,
    userProfile: { ...profile, accountType: profile.account_type },
    themeColor: project.colorPreference,
    userLocale: getUserLocalePreference()
  })

  yield Context.UniversalNav.initialize(context)

  yield fork(handleEventChannel)
}

function* handleEventChannel() {
  if (!Context.UniversalNav?.EventChannel) return

  const eventChannel: EventChannel<EventPayload> = yield call(
    createEventChannel,
    Context.UniversalNav.EventChannel,
    'event'
  )

  while (true) {
    try {
      const eventPayload: EventPayload = yield take(eventChannel)

      if (eventPayload.type === 'account-menu') {
        yield handleAccountMenuEvent(eventPayload.payload)
      }
    } catch (err) {
      console.error('WamClient saga error', err)
    }
  }
}

function* handleAccountMenuEvent({ event }: EventPayload['payload']) {
  switch (event) {
    case 'project-neo-preferences-link|Account Menu|Local Section Link':
      yield put(setShowSettingsDialog(true))
      break
    case 'project-neo-legal-notices-link|Account Menu|Local Section Link':
      yield put(setShowLegalNoticesDialog(true))
      break
    case 'Sign out|Account Menu':
      yield put(logout())
      break

    case 'BUY_CREDITS':
      yield put(setShowPaywall('cta'))
      break

    default:
      break
  }
}

function* handleSetColorPreference({
  payload
}: PayloadAction<PayloadType<typeof setColorPreference>>) {
  yield Context.UniversalNav.changeTheme(payload.color)
}

export default function* universalNavSaga() {
  yield takeEvery(loadUniversalNav.type, handleLoadUniversalNav)
  yield takeEvery(setColorPreference.type, handleSetColorPreference)
}
