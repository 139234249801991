import { bindActionCreators } from '@reduxjs/toolkit'
import { scene } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'

export const useSceneActions = () => {
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(scene.actions, dispatch)
  return boundedActions
}

export const useIsEmptyScene = () => {
  const elements = useSelector((state: RootState) => state.scene.elements)
  return !elements.some(el => el.visible)
}

export type SceneStateProperties = ReturnType<typeof useSceneActions> &
  RootState['scene']

export const useSceneState = <T extends keyof RootState['scene']>(
  key: T
): RootState['scene'][T] => {
  const state = useSelector((state: RootState) => state.scene[key])
  return state
}
