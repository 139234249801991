import React, { FC, useEffect } from 'react'
import { ActionMenu, ColorSwatch, Link, MenuItem, ProgressCircle } from 'ui'
import LibraryThumbnailGrid from './LibraryThumbnailGrid'
import LibraryThumbnail from './LibraryThumbnail'
import InlineAlert from '@components/inlineAlert/InlineAlert'
import { useUserLibrary } from '@hooks/useUserLibrary'
import { UserLibraryBackground } from '@store/slices/userLibrarySlice'
import { EngineBackground } from '@services/engine/types'
import { useTranslations } from 'use-intl'

interface Props {
  onViewAll?: () => void
  totalItems?: number
  disabled?: boolean
}

const UserLibraryBackgroundItems: FC<Props> = ({
  onViewAll,
  totalItems,
  disabled
}) => {
  const t = useTranslations()
  const {
    archiveUserLibraryAsset,
    applyUserLibraryBackground,
    userLibraryBackgrounds,
    fetchUserLibraryBackgrounds
  } = useUserLibrary()

  const showViewAllLink =
    onViewAll &&
    totalItems &&
    userLibraryBackgrounds?.length &&
    userLibraryBackgrounds.length > totalItems

  useEffect(() => {
    fetchUserLibraryBackgrounds()
  }, [])

  function onMenuClick(
    { target: { value } }: any,
    background: UserLibraryBackground
  ) {
    switch (value) {
      case 'user-library-menu-item-apply-background':
        return applyUserLibraryBackground(background.document)
      case 'user-library-menu-item-delete-background':
        return archiveUserLibraryAsset({
          type: 'background',
          assetUuid: background.uuid!
        })
      default:
        return
    }
  }

  if (userLibraryBackgrounds === null) {
    return <ProgressCircle indeterminate />
  }
  if (!userLibraryBackgrounds.length) {
    return <InlineAlert>{t('scene:materials:noBackgroundSaved')}</InlineAlert>
  }
  return (
    <>
      <LibraryThumbnailGrid>
        {userLibraryBackgrounds
          .filter((_, index) => (totalItems ? index < totalItems : true))
          .map(b => (
            <LibraryThumbnail
              key={b.uuid}
              actionMenu={
                <ActionMenu size="s" quiet onchange={e => onMenuClick(e, b)}>
                  <MenuItem
                    value="user-library-menu-item-apply-background"
                    disabled={disabled}
                  >
                    {t('scene:materials:apply')}
                  </MenuItem>
                  <MenuItem value="user-library-menu-item-delete-background">
                    {t('scene:materials:delete')}
                  </MenuItem>
                </ActionMenu>
              }
              label={b.name || ''}
            >
              {b.document.backgroundType !== EngineBackground.SOLID ? (
                <ColorSwatch
                  swatches={[
                    {
                      id: b.document.backgroundColorA,
                      color: b.document.backgroundColorA
                    },
                    {
                      id: b.document.backgroundColorB,
                      color: b.document.backgroundColorB
                    }
                  ]}
                />
              ) : (
                <div
                  style={{
                    height: 24,
                    width: 24,
                    borderRadius: 3,
                    backgroundColor: b.document.backgroundColorA
                  }}
                />
              )}
            </LibraryThumbnail>
          ))}
      </LibraryThumbnailGrid>
      {showViewAllLink && (
        <Link
          style={{ alignSelf: 'flex-end' }}
          variant="secondary"
          onClick={onViewAll}
        >
          {t('scene:materials:viewAll')}
        </Link>
      )}
    </>
  )
}

export default UserLibraryBackgroundItems
