import React, { FC, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import cn from 'classnames'
import styles from './SortableElement.module.scss'

import {
  ElementIconId,
  EngineBlendType,
  SceneNavigatorElement
} from '@services/engine/types'

import Textfield from '@components/textfield/Textfield'

import GroupIcon from '/public/s2_icon_group.svg'
import CubeIcon from '/public/s2_icon_cube.svg'
import CylinderIcon from '/public/s2_icon_cylinder.svg'
import CapsuleIcon from '/public/s2_icon_capsule.svg'
import SphereIcon from '/public/s2_icon_sphere.svg'
import DropIcon from '/public/s2_icon_drop.svg'
import ShoehornIcon from '/public/s2_icon_shoehorn.svg'
import WedgeIcon from '/public/s2_icon_wedge.svg'
import PolygonIcon from '/public/s2_icon_polygon.svg'
import TorusIcon from '/public/s2_icon_torus.svg'
import ConeIcon from '/public/s2_icon_cone.svg'
import StarIcon from '/public/s2_icon_star.svg'
import EggIcon from '/public/s2_icon_egg.svg'
import PinIcon from '/public/s2_icon_pin.svg'
import TextIcon from '/public/s2_icon_3d_text.svg'
import CurvesIcon from '/public/s2_icon_path.svg'
import ChevronIcon from '/public/s2_icon_chevron.svg'
import VisibilityIcon from '/public/s2_icon_visibility.svg'
import VisibilityOffIcon from '/public/s2_icon_visibility_off.svg'
import { useDoubleClick } from '@hooks/useDoubleClick'

interface Props extends SceneNavigatorElement {
  id: string
  isDragging: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  onRename: (name: string) => void
  onShowGroupElements: () => void
  onSelect: (multiSelect: boolean) => void
  toggleVisibility: () => void
}

export const UIElementIdIconMap = {
  [ElementIconId.GROUP]: GroupIcon,
  [ElementIconId.CUBE]: CubeIcon,
  [ElementIconId.CYLINDER]: CylinderIcon,
  [ElementIconId.SPHERE]: SphereIcon,
  [ElementIconId.CAPSULE]: CapsuleIcon,
  [ElementIconId.CONE]: ConeIcon,
  [ElementIconId.TORUS]: TorusIcon,
  [ElementIconId.U]: ShoehornIcon,
  [ElementIconId.DROP]: DropIcon,
  [ElementIconId.TRIANGLE]: WedgeIcon,
  [ElementIconId.CONE_DUPE]: ConeIcon,
  [ElementIconId.STAR]: StarIcon,
  [ElementIconId.POLYGON]: PolygonIcon,
  [ElementIconId.EGG]: EggIcon,
  [ElementIconId.PIN]: PinIcon,
  [ElementIconId.TEXT]: TextIcon,
  [ElementIconId.CURVES]: CurvesIcon
} as const

const UICombineMap = {
  [EngineBlendType.SUBTRACT]: 'Carve',
  [EngineBlendType.INTERSECT]: 'Intersect',
  [EngineBlendType.COLOR]: 'Color',
  [EngineBlendType.REPEL]: 'Repel',
  [EngineBlendType.AVOID]: 'Avoid'
} as const

const SortableElement: FC<Props> = ({
  color,
  combine,
  hovered,
  iconID,
  id,
  isDragging,
  selected,
  name,
  onMouseEnter,
  onMouseLeave,
  onRename,
  onShowGroupElements,
  onSelect,
  toggleVisibility,
  visible
}) => {
  const { attributes, listeners, setNodeRef, isOver, activeIndex, overIndex } =
    useSortable({ id })

  const [showNameInput, setShowNameInput] = useState(false)
  const [nameInputValue, setNameInputValue] = useState(name)

  const handleNameClick = useDoubleClick(() => {
    setNameInputValue(name)
    setShowNameInput(true)
  })

  function handleContainerClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    const multiSelect = event.shiftKey || event.metaKey
    onSelect(multiSelect)
  }

  function handleNameChange() {
    setShowNameInput(false)

    if (nameInputValue && nameInputValue !== name) {
      onRename(nameInputValue)
    }
  }

  const ElementIcon = UIElementIdIconMap[iconID]

  return (
    <div
      ref={setNodeRef}
      className={cn(styles['container'], {
        [styles['selected']]: selected,
        [styles['no-hover-style']]: isDragging || showNameInput || selected,
        [styles['preview-hovering']]: hovered
      })}
      id={`sortable-item-${id}`}
      {...attributes}
      {...listeners}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleContainerClick}>
      {iconID === ElementIconId.GROUP && (
        <div className={styles['left-action-container']}>
          <button
            className={cn(
              styles['clear-btn'],
              styles['show-group-elements-btn']
            )}
            onClick={onShowGroupElements}>
            <ChevronIcon />
          </button>
        </div>
      )}

      <div
        className={cn(styles['inner-container'], {
          [styles['border-top']]: isOver && activeIndex > overIndex,
          [styles['border-bottom']]: isOver && activeIndex < overIndex
        })}>
        <div
          className={styles['element-icon-and-name']}
          onClick={handleNameClick}>
          <div className={styles['element-icon-container']}>
            <ElementIcon
              style={{ ...(iconID !== ElementIconId.GROUP && { color }) }}
            />
          </div>

          {showNameInput ? (
            <Textfield
              autoFocus
              type="text"
              className={styles['name-input']}
              value={nameInputValue}
              onChange={handleNameChange}
              onInput={e => setNameInputValue(e.currentTarget.value)}
              minLength={1}
              maxLength={15}
            />
          ) : (
            <p className={styles['name']}>{name}</p>
          )}
        </div>

        {!showNameInput && (
          <>
            <div className={styles['right-content']}>
              {combine !== EngineBlendType.ADD && (
                <div className={styles['combine-tag']}>
                  {UICombineMap[combine]}
                </div>
              )}

              <button
                className={cn(styles['clear-btn'], styles['visibility-toggle'])}
                onClick={toggleVisibility}>
                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SortableElement
