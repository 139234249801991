import { ComponentType, FC, Fragment, useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import type { AppProps } from 'next/app'
import { Provider as ReduxProvider } from 'react-redux'
import { RootStore } from '@store/store'
import client from '@store/graphql/client'
import { ThemeProvider } from '@contexts/ThemeContext'
import DefaultLayout from '@components/layouts/DefaultLayout'
import '@styles/reset.scss'
import '@styles/utility.scss'
import '@styles/studio.scss'
import '@styles/global-variables.scss'
import AdobeIMS from '@services/auth/IMS'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk'
import dynamic from 'next/dynamic'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Script from 'next/script'
import { LaunchDarklyContextUpdater } from '@components/launchDarklyContextUpdater/LaunchDarklyContextUpdater'
import { initializeLDClient } from '@store/middleware/context'
import { LocaleProvider } from '@concerns/i18n/components/LocaleProvider'
import { ReportForm } from '@components/reportProject/ReportForm'
import {
  FavIconUrl,
  SceneToImageFavIconUrl,
  SceneToImageStudioEnvironment,
  StudioEnvironmentQueryParam
} from '@constants/appConstants'
import { FavIconProvider } from '@contexts/FavIconContext'
import { useInitNewRelic } from '@hooks/useInitNewRelic'
import { BrowserCompatibilityChecker } from '@components/browserCompatibilityChecker/BrowserCompatibilityChecker'
import { MetaPixelTrackerScript } from '@concerns/monitoring/components/analytics/MetaPixelTrackerScript'
import { GoogleTagManagerScript } from '@concerns/monitoring/components/analytics/GoogleTagManagerScript'
import { TiktokPixelTrackerScript } from '@concerns/monitoring/components/analytics/TiktokPixelTrackerScript'

dayjs.extend(relativeTime)

type LayoutProps = {
  children: React.ReactNode
}

const layouts: { [key: string]: FC<LayoutProps> } = {
  DefaultLayout,
  none: ({ children }) => <>{children}</>
}

const RequireAuth = dynamic(() => import('@services/auth/RequireAuth'), {
  ssr: false
})

const AppRoot = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const [favIconUrl, setFavIconUrl] = useState<string>(FavIconUrl)
  const Layout = layouts[Component.layout] || DefaultLayout
  // TODO remove use of react-spectrum (ThemeProvider is required for react-spectrum)
  const Theme = Component.themeProvider === 'none' ? Fragment : ThemeProvider
  const flags = useFlags()

  useInitNewRelic()

  useEffect(() => {
    DocumentEventObserver.initialize()
    AdobeIMS.initialize()
    initializeLDClient()

    // The studio environment is scene to image. We cannot use useStudioEnvironment here because this is outside of the Redux Provider
    if (
      new URLSearchParams(window.location.search).get(
        StudioEnvironmentQueryParam
      ) === SceneToImageStudioEnvironment
    ) {
      setFavIconUrl(SceneToImageFavIconUrl)
    }

    return () => {
      DocumentEventObserver.terminate()
    }
  }, [])

  return (
    <>
      <Head>
        <meta name="description" content="Project Neo (Beta) from Adobe" />
        <meta name="application-name" content="Project Neo (Beta)" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#fff" />
        <link rel="manifest" href="/manifest.json" />
        <link id="fav-icon-link" rel="shortcut icon" href={favIconUrl} />

        {/* Apple mobile/MS application */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Project Neo (Beta)" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />

        {/* OpenGraph */}
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:title" property="og:title" content="Project Neo (Beta)" />
        <meta
          key="og:site_name"
          property="og:site_name"
          content="Project Neo (Beta)"
        />
        <meta
          key="og:url"
          property="og:url"
          content="https://projectneo.adobe.com"
        />
        <meta
          key="og:description"
          property="og:description"
          content="Project Neo (Beta) from Adobe"
        />
        <meta
          key="og:image"
          property="og:image"
          content="https://projectneo.adobe.com/landing_page/neow_ui_logo.png"
        />

        {/* Twitter metadata */}
        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />

        {/* Adobe Tag Manager */}
        <script
          id="neo_adobe_tagmanager"
          src={process.env.NEXT_PUBLIC_ADOBE_TAGMANAGER_URL}
          async
        />
      </Head>

      {/* Embed Adobe fonts, not in next/Head */}
      <Script
        id="neo_adobe_fonts"
        strategy="afterInteractive"
        src="https://use.typekit.net/bye5wyl.js"
        onError={e => console.error('Typekit (bye5wyl) error  ', e)}
        onLoad={() => {
          try {
            window.Typekit.load({ async: true })
          } catch (e) {
            console.error('Error loading neo_adobe_fonts: ', e)
          }
        }}
      />

      <LocaleProvider>
        <ApolloProvider client={client}>
          <ReduxProvider store={RootStore}>
            <LaunchDarklyContextUpdater />
            <Theme>
              <FavIconProvider setFavIconUrl={setFavIconUrl}>
                <BrowserCompatibilityChecker>
                  <RequireAuth>
                    <Layout>
                      <ReportForm />
                      <Component key={router.asPath} {...pageProps} />
                    </Layout>
                  </RequireAuth>
                </BrowserCompatibilityChecker>
                {flags['debug-tf-fx-fullstory'] && (
                  <Script id="neo_fullstory">
                    {`
                  window['_fs_host'] = 'fullstory.com';
                  window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                  window['_fs_org'] = 'o-1X0HP2-na1';
                  window['_fs_namespace'] = 'FULLSTORY';
                  !function(m,n,e,t,l,o,g,y){var s,f,a=function(h){
                    return!(h in m)||(m.console&&m.console.log&&m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'),!1)}(e)
                    ;function p(b){var h,d=[];function j(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}
                    d&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],j())}}return b(r(0),r(1)),{
                      then:function(b,h){return p((function(r,i){d.push([b,h,r,i]),j()}))}}}a&&(g=m[e]=function(){var b=function(b,d,j,r){function i(i,c){
                        h(b,d,j,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,""),"function"==typeof Promise?new Promise(i):p(i)):h(b,d,j,c,c,r)}
                        ;function h(h,d,j,r,i,c){return b._api?b._api(h,d,j,r,i,c):(b.q&&b.q.push([h,d,j,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){
                          "function"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var j=0;j<d.length;j++)h(d[j]);d.length=0,d.push=h}},function(){
                            (o=n.createElement(t)).async=!0,o.crossOrigin="anonymous",o.src="https://"+l,o.onerror=function(){y("Error loading "+l)}
                            ;var b=n.getElementsByTagName(t)[0];b&&b.parentNode?b.parentNode.insertBefore(o,b):n.head.appendChild(o)}(),function(){function b(){}
                            function h(b,h,d){g(b,h,d,1)}function d(b,d,j){h("setProperties",{type:b,properties:d},j)}function j(b,h){d("user",b,h)}function r(b,h,d){j({
                              uid:b},d),h&&j(h,d)}g.identify=r,g.setUserVars=j,g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,j){h("trackEvent",{
                                name:b,properties:d},j)},g.anonymize=function(){r(!1)},g.shutdown=function(){h("shutdown")},g.restart=function(){h("restart")},
                                g.log=function(b,d){h("log",{level:b,msg:d})},g.consent=function(b){h("setIdentity",{consent:!arguments.length||b})}}(),s="fetch",
                                f="XMLHttpRequest",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){return g._w[s].apply(this,arguments)}),g._v="2.0.0")
                                }(window,document,window._fs_namespace,"script",window._fs_script);
                                `}
                  </Script>
                )}

                {flags['base-pf-analytics-pixel-tracking'] && (
                  <>
                    <MetaPixelTrackerScript />
                    <GoogleTagManagerScript />
                    <TiktokPixelTrackerScript />
                  </>
                )}
              </FavIconProvider>
            </Theme>
          </ReduxProvider>
        </ApolloProvider>
      </LocaleProvider>
    </>
  )
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID as string,
  reactOptions: {
    useCamelCaseFlagKeys: false
  }
})(AppRoot as ComponentType<{}>)
