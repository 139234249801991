import { PayloadAction } from '@reduxjs/toolkit'
import { Channel } from 'phoenix'
import {
  LocalUserEmojiMessage,
  LocalUserMouseMoveEvent,
  LocalUserInputMessage,
  receivedRemoteUserEmojiMessageEvent,
  RemoteUserEmojiMessageEvent,
  RemoteUserMouseMoveEvent,
  RemoteUserLocationChangeEvent,
  sendLocalUserEmojiMessageValue,
  sendLocalUserMousePosition,
  sendLocalUserInputMessageValue,
  RemotePropertyChangeEvent,
  LocalUserSubmitMessage,
  sendLocalUserSubmitMessageValue,
  setRemoteUsers,
  RemoteSceneChangeEvent,
  sendLocalUserPlayerChangeEvent,
  PlayerChangeEvent
} from '@store/slices/syncSlice'
import { delay, fork, put, select, take } from 'redux-saga/effects'
import Context from '@store/middleware/context'
import setSceneProperty from '../document/setSceneProperty'
import { RootState } from '@store/store'
import {
  EngineCommitChange,
  EngineCommitOrigin,
  EngineData
} from '@services/engine/types'
import { CANVAS_CONTAINER_ID } from '@components/studio/Studio'
import { PropertyPayload } from '@store/slices/sceneSlice'

export function registerLocalUserMouseMoveListener() {
  const root = document.getElementById(CANVAS_CONTAINER_ID)
  if (!root) {
    return
  }

  // TODO: enable this handler for frames
  // root.addEventListener('mousemove', e => {
  //   const x = (e.pageX / window.innerWidth) * 100
  //   const y = (e.pageY / window.innerHeight) * 100
  //   RootStore.dispatch(sendLocalUserMousePosition({ x, y }))
  // })
}

export function* handleLocalUserInputActions(
  { type, payload }: PayloadAction<any>,
  channel: Channel
) {
  switch (type) {
    case sendLocalUserMousePosition.type:
      yield channel.push('mouse_move', payload as LocalUserMouseMoveEvent)
      break

    case sendLocalUserInputMessageValue.type:
      yield channel.push('input_message', payload as LocalUserInputMessage)
      break

    case sendLocalUserSubmitMessageValue.type:
      yield channel.push('submit_message', payload as LocalUserSubmitMessage)
      break

    case sendLocalUserEmojiMessageValue.type:
      yield channel.push('emoji_message', payload as LocalUserEmojiMessage)
      break

    case sendLocalUserPlayerChangeEvent.type:
      yield channel.push('player_change', payload as PlayerChangeEvent)
      break

    default:
      break
  }
}

export function* handleRemoteUserMouseMoveEvent({
  payload
}: PayloadAction<RemoteUserMouseMoveEvent>) {
  // TODO: enable this handler for frames
  // const cursor = document.getElementById(
  //   `sync-cursor-${payload.remote_user_uuid}`
  // )
  // if (!cursor) return
  // cursor.style.setProperty('top', `${payload.y}%`)
  // cursor.style.setProperty('left', `${payload.x}%`)
}

export function* handleRemoteUserPositionChangeEvent({
  payload
}: PayloadAction<RemoteUserLocationChangeEvent>) {
  try {
    const {
      auth: { localUser },
      scene: { engineState }
    }: RootState = yield select((state: RootState) => state)

    if (!localUser && engineState !== 'INITIALIZED_AND_DOCUMENT_LOADED') {
      return
    }

    // This check is necessary to handle the case where the same user enters
    // the same project in two different tabs, two different browsers, two
    // different devices, etc.
    if (localUser?.uuid !== payload.remote_user_uuid) {
      yield fork(handleRemoteUserLocation, payload)
      yield fork(handleLocalUserFollowingRemoteUser, payload)
    }
  } catch (e) {
    console.error(e)
  }
}

export function* handleRemoteUserLocation(
  payload: RemoteUserLocationChangeEvent
) {
  try {
    const cursor = document.getElementById(
      `sync-cursor-${payload.remote_user_uuid}`
    )

    // There is a race condition where we attempt to get the cursor when the cursor may or may not
    // be in the DOM. When a remote user first enters the project the user is in, the onSync event
    // fires (in eventChannels.ts). When this event fires, setRemoteUsers is called to update the
    // Redux store, and consequently, a Cursor component is rendered.

    // However, very shortly after onSync is fired, a location_change event is also fired. This
    // event being fired ultimately leads to this function being called.

    // The race condition is that sometimes the cursor isn't placed in the DOM by the time
    // we attempt to get the cursor from the DOM - and sometimes it is.

    // It's best not to use sagas to resolve this race condition because the location_event
    // cannot wait for onSync to fire and complete before firing itself, because location_event
    // needs to fire constantly as users within the project move around, and onSync is firing
    // throughout the time that the user is in a project, because other people are entering and
    // leaving the project at different times.

    // It is best to simply not do anything if the cursor is not found in the DOM.
    if (!cursor) return

    const rectangleHeight = cursor?.getBoundingClientRect().height

    yield fork(Context.Engine?.setRemoteUserLocation, {
      ...payload,
      appearance: { size_css: rectangleHeight }
    })
  } catch (e) {
    console.error(e)
  }
}

export function* handleLocalUserFollowingRemoteUser(
  payload: RemoteUserLocationChangeEvent
) {
  try {
    const following = yield select(
      (state: RootState) => state.sync.localUserFollowingRemoteUser
    )

    if (following && following.uuid === payload.remote_user_uuid) {
      yield fork(Context.Engine?.setLocalUserLocation, {
        location: payload.location
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export function* handleRemoteUserEmojiMessageEvent({
  payload
}: PayloadAction<RemoteUserEmojiMessageEvent>) {
  yield delay(3000)
  yield put(
    receivedRemoteUserEmojiMessageEvent({ ...payload, emoji_message: '' })
  )
}

export function* handleRemotePropertyChangeEvent({
  payload
}: PayloadAction<RemotePropertyChangeEvent>) {
  yield fork(setSceneProperty, {
    key: payload.property.key,
    value: payload.property.value
  } as PropertyPayload)
}
