import React, { FC, ReactNode, useContext, memo } from 'react'
import { FontPropertiesDynamicImport } from './FontPropertiesDynamicImport'
import { useAuthState } from '@hooks/useAuth'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import styles from '@styles/components/FontPicker.module.scss'
import { ElementSize } from '@store/slices/projectSlice'
import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'

export enum FontPropertiesType {
  COMPLETE = 'complete',
  FAMILIES = 'families',
  STYLES = 'styles'
}

enum Environment {
  STAGE = 'stage',
  PROD = 'prod'
}

enum AppEntitlement {
  FREEMIUM = 'freemium',
  PAID = 'paid',
  FREE = 'free'
}

interface Props {
  fontSizeSlot?: ReactNode
  size: ElementSize
  type?: FontPropertiesType
  disabled: boolean
  previewText: string
}

const FontPicker: FC<Props> = ({
  fontSizeSlot,
  size,
  type = FontPropertiesType.COMPLETE,
  disabled,
  previewText
}) => {
  const { activeLocale } = useContext(LocaleContext)
  const localUser = useAuthState('localUser')
  const authToken = useAuthState('authToken')
  const selectedFontPostScriptName = useSceneState('selectedFontPostScriptName')

  const { changeFont } = useSceneActions()

  if (!localUser || !authToken || !localUser?.adobeUserId) return null
  return (
    <FontPropertiesDynamicImport
      disabled={disabled}
      size={size}
      className={styles['picker']}
      previewText={previewText || 'Lorem ipsum'}
      showLanguageSuggestions
      onItemChange={e => {
        if (e?.detail?.fontId) {
          changeFont({ fontId: e?.detail?.fontId })
        }
      }}
      valueAsFontIdentifier={{ postscriptName: selectedFontPostScriptName }}
      type={type}
      appEntitlement={AppEntitlement.PAID}
      accessToken={authToken}
      userId={localUser.adobeUserId}
      locale={activeLocale}
      clientId={process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID}
      env={
        process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'prd'
          ? Environment.PROD
          : Environment.STAGE
      }
    >
      {fontSizeSlot && <div slot="font-size">{fontSizeSlot}</div>}
    </FontPropertiesDynamicImport>
  )
}

export default memo(FontPicker)
