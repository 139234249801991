import React from 'react'
import { Button, Icon } from 'ui'
import PremiumIcon from '/public/s2_icon_premium.svg'
import { useTranslations } from 'use-intl'
import { useAccessProfileState } from '@hooks/useAccessProfile'
import { useFirefly } from '@hooks/useFirefly'

export function FireflyUpgradeButton() {
  const t = useTranslations()

  const fireflyFulfillableItemCreditModel = useAccessProfileState(
    'fireflyFulfillableItemCreditModel'
  )

  const { setShowPaywall } = useFirefly()

  function handleButtonClick() {
    setShowPaywall('cta')
  }

  return (
    <Button className="firefly-upgrade-button" onClick={handleButtonClick}>
      <Icon slot="icon">
        <PremiumIcon />
      </Icon>
      {t(
        fireflyFulfillableItemCreditModel === 'PAID-UNEXHAUSTABLE'
          ? 'studio:firefly:upgradeButton:paid-unexhaustable'
          : 'studio:firefly:upgradeButton:default'
      )}
    </Button>
  )
}
