import { FC } from 'react'
import { FieldLabel } from 'ui'
import Typography from '@components/base/Typography/Typography'
import styles from '@styles/components/VideoExportDialog.module.scss'
import { useTranslations } from 'use-intl'
import { UseTranslationsHookResult } from '@concerns/i18n/types'
import {
  NumberFieldValidator,
  NumberFieldWithValidation
} from '@components/NumberField/NumberFieldWithValidation'
import { useVideoExport } from '@contexts/VideoExportContext'
import {
  InputErrorMessage,
  InputErrorMessageType
} from '@components/inputErrorMessage/InputErrorMessage'

export type AnimationTypeValue = 'turntable' | 'boomerang' | 'light'

export const getAnimationTypes = (t: UseTranslationsHookResult) => {
  return [
    {
      label: t('studio:downloadMenu:mp4:turntable'),
      value: 'turntable'
    },
    {
      label: t('studio:downloadMenu:mp4:boomerang'),
      value: 'boomerang'
    },
    {
      label: t('studio:downloadMenu:mp4:shadowPlay'),
      value: 'light'
    }
  ]
}

export const getQualityOptions = (t: UseTranslationsHookResult) => {
  ;``
  return [
    {
      label: t('studio:downloadMenu:mp4:high'),
      value: 1.0,
      upscale: true
    },
    {
      label: t('studio:downloadMenu:mp4:medium'),
      value: 0.5,
      upscale: false
    },
    {
      label: t('studio:downloadMenu:mp4:low'),
      value: 0.3,
      upscale: false
    }
  ]
}

export interface AnimationSettingsType<T> {
  turntable: {
    duration: T
    numberOfTurns: T
  }
  boomerang: {
    duration: T
    numberOfBackAndForth: T
    horizontalAngleAmplitude: T
  }
  light: {
    duration: T
    numberOfTurns: T
  }
}

export type AnimationValidationError = AnimationSettingsType<string>

const VideoAnimationSettings: FC = () => {
  const t = useTranslations()
  const {
    durationError,
    rotationsError,
    loopsError,
    angleError,
    animationType
  } = useVideoExport()

  function renderErrorMessages(
    durationError: InputErrorMessageType,
    rotationsError: InputErrorMessageType,
    loopsError: InputErrorMessageType,
    angleError: InputErrorMessageType
  ) {
    if (durationError) return <InputErrorMessage error={durationError} />

    if (
      rotationsError &&
      (animationType === 'turntable' || animationType === 'light')
    ) {
      return <InputErrorMessage error={rotationsError} />
    }

    if (loopsError && animationType === 'boomerang') {
      return <InputErrorMessage error={loopsError} />
    }

    if (angleError && animationType === 'boomerang') {
      return <InputErrorMessage error={angleError} />
    }
  }

  return (
    <div className="flex flex-col">
      <Typography variant="h4">
        {t('studio:downloadMenu:mp4:animationSettings')}
      </Typography>
      <div className="flex gap-xxs">
        {animationType === 'turntable' && <TurntableSettings />}
        {animationType === 'boomerang' && <BoomerangSettings />}
        {animationType === 'light' && <LightSettings />}
      </div>
      <p className={styles['error']}>{}</p>
      {renderErrorMessages(
        durationError,
        rotationsError,
        loopsError,
        angleError
      )}
    </div>
  )
}

function getDurationValidators(
  t: UseTranslationsHookResult
): NumberFieldValidator[] {
  return [
    {
      validator: value => value >= 1,
      errorMessage: t('studio:downloadMenu:mp4:durationGreaterThanOne')
    },
    {
      validator: value => value <= 1000000,
      errorMessage: t('studio:downloadMenu:mp4:cannotExceedMillion', {
        parameter: 'Duration'
      })
    }
  ]
}

function getRotationsValidators(
  t: UseTranslationsHookResult
): NumberFieldValidator[] {
  return [
    {
      validator: value => value >= 1,
      errorMessage: t('studio:downloadMenu:mp4:atLeastOneRotation')
    },
    {
      validator: value => value <= 1000000,
      errorMessage: t('studio:downloadMenu:mp4:cannotExceedMillion', {
        parameter: 'Rotations'
      })
    }
  ]
}

function getLoopsValidators(
  t: UseTranslationsHookResult
): NumberFieldValidator[] {
  return [
    {
      validator: value => value >= 1,
      errorMessage: t('studio:downloadMenu:mp4:atLeastOneLoop')
    },
    {
      validator: value => value <= 1000000,
      errorMessage: t('studio:downloadMenu:mp4:cannotExceedMillion', {
        parameter: 'Loops'
      })
    }
  ]
}

function getAngleValidators(
  t: UseTranslationsHookResult
): NumberFieldValidator[] {
  return [
    {
      validator: value => value >= 5,
      errorMessage: t('studio:downloadMenu:mp4:angleMinSize')
    }
  ]
}

function TurntableSettings() {
  const t = useTranslations()
  const {
    duration,
    rotations,
    setDuration,
    setRotations,
    setDurationError,
    setRotationsError
  } = useVideoExport()

  return (
    <>
      <div className="flex flex-col">
        <FieldLabel for="turntable-duration">
          {t('studio:downloadMenu:mp4:duration')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="turntable-duration"
          value={duration}
          onInput={e => setDuration((e.target as any).value)}
          onChange={e => setDuration((e.target as any).value)}
          validators={getDurationValidators(t)}
          onValidationSuccessful={() => setDurationError(null)}
          onValidationFailed={error => setDurationError(error as string)}
        />
      </div>
      <div className="flex flex-col">
        <FieldLabel for="turntable-rotations">
          {t('studio:downloadMenu:mp4:rotations')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="turntable-rotations"
          step={1}
          value={rotations}
          onInput={e => setRotations((e.target as any).value)}
          onChange={e => setRotations((e.target as any).value)}
          validators={getRotationsValidators(t)}
          onValidationSuccessful={() => setRotationsError(null)}
          onValidationFailed={error => setRotationsError(error as string)}
        />
      </div>
    </>
  )
}

function BoomerangSettings() {
  const t = useTranslations()
  const {
    duration,
    loops,
    angle,
    setDuration,
    setLoops,
    setAngle,
    setDurationError,
    setLoopsError,
    setAngleError
  } = useVideoExport()

  return (
    <>
      <div className="flex flex-col">
        <FieldLabel for="boomerang-duration">
          {t('studio:downloadMenu:mp4:duration')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="boomerang-duration"
          value={duration}
          onInput={e => setDuration((e.target as any).value)}
          onChange={e => setDuration((e.target as any).value)}
          validators={getDurationValidators(t)}
          onValidationSuccessful={() => setDurationError(null)}
          onValidationFailed={error => setDurationError(error as string)}
        />
      </div>
      <div className="flex flex-col">
        <FieldLabel for="boomerang-loops">
          {t('studio:downloadMenu:mp4:loops')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="boomerang-loops"
          step={1}
          value={loops}
          onInput={e => setLoops((e.target as any).value)}
          onChange={e => setLoops((e.target as any).value)}
          validators={getLoopsValidators(t)}
          onValidationSuccessful={() => setLoopsError(null)}
          onValidationFailed={error => setLoopsError(error as string)}
        />
      </div>
      <div className="flex flex-col">
        <FieldLabel for="boomerang-angle">
          {t('studio:downloadMenu:mp4:angle')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="boomerang-angle"
          value={angle}
          onInput={e => setAngle((e.target as any).value)}
          onChange={e => setAngle((e.target as any).value)}
          validators={getAngleValidators(t)}
          onValidationSuccessful={() => setAngleError(null)}
          onValidationFailed={error => setAngleError(error as string)}
        />
      </div>
    </>
  )
}

function LightSettings() {
  const t = useTranslations()
  const {
    duration,
    rotations,
    setDuration,
    setRotations,
    setDurationError,
    setRotationsError
  } = useVideoExport()

  return (
    <>
      <div className="flex flex-col">
        <FieldLabel for="light-duration">
          {t('studio:downloadMenu:mp4:duration')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="light-duration"
          value={duration}
          onInput={e => setDuration((e.target as any).value)}
          onChange={e => setDuration((e.target as any).value)}
          validators={getDurationValidators(t)}
          onValidationSuccessful={() => setDurationError(null)}
          onValidationFailed={error => setDurationError(error as string)}
        />
      </div>
      <div className="flex flex-col">
        <FieldLabel for="light-rotations">
          {t('studio:downloadMenu:mp4:rotations')}
        </FieldLabel>
        <NumberFieldWithValidation
          name="light-rotations"
          step={1}
          value={rotations}
          onInput={e => setRotations((e.target as any).value)}
          onChange={e => setRotations((e.target as any).value)}
          validators={getRotationsValidators(t)}
          onValidationSuccessful={() => setRotationsError(null)}
          onValidationFailed={error => setRotationsError(error as string)}
        />
      </div>
    </>
  )
}

export default VideoAnimationSettings
