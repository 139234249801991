import { DialogBase, Button, Icon } from 'ui'
import styles from '@styles/components/FireflySceneToImageFreeUserDialog.module.scss'
import { useFirefly } from '@hooks/useFirefly'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'
import FireflyIcon from '/public/icon_s2i_get_started.svg'
import { Badge } from 'ui'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from '@constants/localStorage'

export function FireflyFreeUserDialog() {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  const { setShowFreeUserDialog, showFreeUserDialog } = useFirefly()

  function closeDialog() {
    setShowFreeUserDialog(false)

    localStorage.setItem(
      LocalStorageKey.fireflyViewedFreeUserOnboardingDialog,
      LocalStorageBooleanValue.TRUE
    )
  }

  return (
    <DialogBase underlay open={showFreeUserDialog}>
      <div className={styles['dialog']}>
        <img
          src="/firefly/firefly-scene-to-image-free-user-dialog-image.png"
          alt="free user dialog"
        />
        <div className={styles['content']}>
          <Badge className={styles['badge']} size={'s'} variant="neutral">
            {t('studio:firefly:onboarding:free-user:badge')}
          </Badge>
          <div className={styles['text-container']}>
            <h2>{t('studio:firefly:onboarding:free-user:header')}</h2>
            <p>{t('studio:firefly:onboarding:free-user:body')}</p>
          </div>
          <Button
            onClick={closeDialog}
            variant="accent"
            size={size}
            className={styles['button']}
          >
            <Icon size={size} slot="icon">
              <FireflyIcon />
            </Icon>
            {t('studio:firefly:onboarding:free-user:button')}
          </Button>
        </div>
      </div>
    </DialogBase>
  )
}
