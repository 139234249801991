import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TrackingEvent } from '@constants/analytics'

export const monitoring = createSlice({
  name: 'monitoring',
  initialState: {},
  reducers: {
    sendEvent: (_, { payload }: PayloadAction<TrackingEvent>) => {}
  }
})

export const { sendEvent } = monitoring.actions

export default monitoring.reducer
