import { all, put, putResolve, select, takeEvery } from 'redux-saga/effects'
import {
  ArchiveUserLibraryAssetRequest,
  CreateUserLibraryAssetRequest,
  UserLibraryBackground,
  UserLibraryMaterial,
  applyUserLibraryBackground,
  applyUserLibraryMaterialToSelection,
  archiveUserLibraryAsset,
  createUserLibraryAsset,
  fetchAllUserLibraryAssets,
  setAllUserLibraryAssets,
  fetchUserLibraryBackgrounds,
  setUserLibraryBackgrounds,
  fetchUserLibraryMaterials,
  setUserLibraryMaterials
} from '@store/slices/userLibrarySlice'
import ApolloClient from '@store/graphql/client'
import {
  Asset,
  UserLibraryAssetsDocument,
  UserLibraryAssetsQueryResult,
  ArchiveUserLibraryAssetDocument,
  CreateUserLibraryAssetDocument,
  Maybe
} from '@store/graphql/__generated__/schema'
import { PayloadAction } from '@reduxjs/toolkit'
import { SceneState, setPropertyState } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import { PropertyPayload } from '../slices/sceneSlice'
import Context from './context'
import { QueryOptions } from '@apollo/client'

function deserializeAsset(asset: Maybe<Asset>) {
  return {
    ...asset,
    document: JSON.parse(asset!.document!)
  }
}

const baseQuery: Pick<QueryOptions, 'fetchPolicy' | 'query'> = { fetchPolicy: 'network-only', query: UserLibraryAssetsDocument }

function *handleFetchAllUserLibraryAssets() {
  const response: UserLibraryAssetsQueryResult = yield ApolloClient.query(baseQuery)

  let assets = response.data?.assets
  assets = !!assets ? assets : []

  yield put(setAllUserLibraryAssets(assets.map(deserializeAsset)))
}

function *handleFetchUserLibraryMaterials() {
  const response: UserLibraryAssetsQueryResult = yield ApolloClient.query({
    ...baseQuery,
    variables: { input: { type: 'material' } }
  })

  let assets = response.data?.assets
  assets = !!assets ? assets : []

  yield put(setUserLibraryMaterials(assets.map(deserializeAsset)))
}

function *handleFetchUserLibraryBackgrounds() {
  const response: UserLibraryAssetsQueryResult = yield ApolloClient.query({
    ...baseQuery,
    variables: { input: { type: 'background' } }
  })

  let assets = response.data?.assets
  assets = !!assets ? assets : []

  yield put(setUserLibraryBackgrounds(assets.map(deserializeAsset)))
}

// Create any type of asset
function* handleCreateUserLibraryAsset({
  payload
}: PayloadAction<CreateUserLibraryAssetRequest>) {
  yield ApolloClient.mutate({
    mutation: CreateUserLibraryAssetDocument,
    variables: {
      name: payload.name.trim(),
      type: payload.type,
      document: JSON.stringify({
        ...payload.document,
        ...(payload.type === 'material' &&
          Context.Engine?.getMaterialProperties())
      })
    }
  })

  switch (payload.type) {
    case 'background':
      yield put(fetchUserLibraryBackgrounds())
      return
    case 'material':
      yield put(fetchUserLibraryMaterials())
      return
    default:
      yield put(fetchAllUserLibraryAssets())
      return
  }
}

function* handleApplyUserLibraryMaterialToSelection({
  payload: document
}: PayloadAction<UserLibraryMaterial['document']>) {
  const sceneState: SceneState = yield select((state: RootState) => state.scene)

  for (const materialProperty in document) {
    console.log(
      `[REACT]: Setting ${materialProperty} to ${document[materialProperty]}`
    )

    if (
      sceneState[materialProperty as keyof SceneState] !==
      document[materialProperty]
    ) {
      yield putResolve(
        setPropertyState({
          key: materialProperty,
          value: document[materialProperty]
        } as PropertyPayload)
      )
    }
  }
}

function* handleApplyUserLibraryBackground({
  payload: document
}: PayloadAction<UserLibraryBackground['document']>) {
  const sceneState: SceneState = yield select((state: RootState) => state.scene)

  for (const backgroundProperty in document) {
    if (
      sceneState[backgroundProperty as keyof SceneState] !==
      document[backgroundProperty]
    ) {
      console.log(
        `[REACT]: Setting ${backgroundProperty} to ${document[backgroundProperty]}`
      )
      yield putResolve(
        setPropertyState({
          key: backgroundProperty,
          value: document[backgroundProperty]
        } as PropertyPayload)
      )
    }
  }
}

// Archive an asset
function* handleArchiveUserLibraryAsset({
  payload
}: PayloadAction<ArchiveUserLibraryAssetRequest>) {
  yield ApolloClient.mutate({
    mutation: ArchiveUserLibraryAssetDocument,
    variables: {
      assetUuid: payload.assetUuid
    }
  })

  switch (payload.type) {
    case 'background':
      yield put(fetchUserLibraryBackgrounds())
      return
    case 'material':
      yield put(fetchUserLibraryMaterials())
      return
    default:
      yield put(fetchAllUserLibraryAssets())
      return
  }
}

export default function* userLibrarySaga() {
  yield all([
    takeEvery(fetchAllUserLibraryAssets.type, handleFetchAllUserLibraryAssets),
    takeEvery(fetchUserLibraryBackgrounds.type, handleFetchUserLibraryBackgrounds),
    takeEvery(fetchUserLibraryMaterials.type, handleFetchUserLibraryMaterials),
    takeEvery(createUserLibraryAsset.type, handleCreateUserLibraryAsset),
    takeEvery(archiveUserLibraryAsset, handleArchiveUserLibraryAsset),
    takeEvery(
      applyUserLibraryMaterialToSelection.type,
      handleApplyUserLibraryMaterialToSelection
    ),
    takeEvery(applyUserLibraryBackground.type, handleApplyUserLibraryBackground)
  ])
}
