export enum AppEntitlement {
  /**
   * The app is paid, and whichever fonts the user is entitled to would be there for in-app usage.
   */
  PAID = 0,
  /**
   * Default. The app is freemium, and user sees all fonts for in-app upsell experience
   */
  FREEMIUM = 1,
  /**
   * The app is free and used for only showing free fonts due to app or user model limitations
   */
  FREE = 2
}

export enum Entitlement {
  FULL = 'full',
  TRIAL = 'trial',
  ANONYMOUS = 'anonymous'
}

export enum StatusCode {
  UNINITIALIZED = 'UNINITIALIZED',
  WORKING = 'WORKING',
  DONE = 'DONE',
  PAUSED = 'PAUSED',
  ERROR_OCCURED = 'ERROR_OCCURED'
}

export enum ErrorCode {
  NONE = 0,
  OFFLINE = 1,
  SERVER_UNREACHABLE = 2,
  AUTH_FAILURE = 3,
  PROXY_FAILURE = 4,
  JSON_PARSE_ERROR = 5,
  BAD_REQUEST = 6,
  FORBIDDEN = 7,
  FONT_NOT_FOUND = 8,
  TOO_MANY_REQUESTS = 9,
  DISK_CACHE_CAPACITY_REACHED = 10,
  DISK_WRITE_FAILED = 11,
  BAD_FONT_DATA = 12,
  DUPLICATE_BYOF = 13,
  INVALID_CONFIGURATION = 14,
  SYSTEM_FONTS_SECURITY_ERROR = 15,
  SYSTEM_FONTS_API_NOT_AVAILABLE = 16,
  SYSTEM_FONTS_API_NOT_AVAILABLE_FOR_WORKER_THREAD = 17,
  SYSTEM_FONTS_UNKNOWN = 18,
  ABORT_ERROR = 19,
  UNKNOWN = 20
}

/**
 * Represents an error object with an error code, error message, and a flag indicating if the error is fatal.
 */
export class TQError {
  /** Error code */
  errCode!: ErrorCode
  /** Error message */
  errMsg!: string
  /** Is the error fatal */
  isFatal!: boolean
  httpStatus!: number
  /**
   * The Error constructor function takes three parameters, errorCode, errorMessage and fatal. The errorCode
   * parameter is set to ErrorCode.NONE by default. The errorMessage parameter is set to "No Error" by
   * default. The fatal parameter is set to false by default. The constructor function sets the errCode
   * property to the errorCode parameter, the errMsg property to the errorMessage parameter and the
   * isFatal property to the fatal parameter.
   * @param {ErrorCode} errorCode - This is the error code that you want to return.
   * @param [errorMessage=No Error] - The error message that will be displayed to the user.
   * @param [fatal=false] - If true, the error is fatal and the app should be closed.
   */
  constructor(
    errorCode?: ErrorCode,
    errorMessage?: string,
    fatal?: boolean,
    httpStatus?: number
  ) {}
}

/**
 * An enumeration of the different operation modes available for the application.
 * @enum {number}
 * @readonly
 * @property {number} DATA - The default operation mode for the application.
 * @property {number} CHECK - The operation mode for checking data.
 * @property {number} FORCE_REFRESH - The operation mode for forcing a data refresh.
 */
export enum OperationMode {
  DATA = 0, // will schedule download for a data if data is not present in TC cache
  CHECK, // will populate provided attributes but won't download data if absent in TCCache // TODO
  FORCE_REFRESH // will force fetch the data and override the data present on TCCache, if any // TODO
}

/**
 * Adobe Fonts is making it possible to use fonts without requiring they are activated. If a font file was served
 * to a trusted client application and the application uses the font, Adobe Fonts pays royalties even if the font
 * was not installed. To enable royalty accounting, the application needs to inform Adobe Fonts that a given font was used.
 * https://wiki.corp.adobe.com/display/fonts/Font+Uses+API
 */
export enum FontUsageOperation {
  /**
   * Existing document open resulting in font usage should be sent with OPEN event (with the exception of COLLABORATION_USE event)
   */
  OPEN = 'open',
  /**
   * Interactive selection of font by user should be sent with APPLY provided it's entitled (if not entitled, it should be PREVIEW)
   */
  APPLY = 'apply',
  /**
   * COLLABORATION_USE should be sent when a user (anonymous or logged-in) who doesn't have the entitlement
   * to a font edits text. Also collaboration-use should be sent with a readonly param when you open a document
   * and in the font specific text layer you are not allowing to edit the fonts.
   */
  COLLABORATION_USE = 'collaboration-use',
  /**
   * PREVIEW should be sent when a user (anonymous or logged-in) is using royalty-bearing fonts in a document,
   * but saving or publishing the document is prevented by a paywall.
   */
  PREVIEW = 'preview'
}
