import { ComponentProps, FC, useEffect, useState } from 'react'
import { NumberField } from 'ui'
import styles from '@styles/components/NumberField.module.scss'

type ValidationError = string
type ValidationResultSuccess = { result: true; value: number }
type ValidationResultFail = { result: false; errorMessage?: ValidationError }
type ValidationResult = ValidationResultSuccess | ValidationResultFail

export type NumberFieldValidator = {
  validator: (value: number) => boolean
  errorMessage?: ValidationError
}

type Props = {
  validators?: NumberFieldValidator[]
  onValidationSuccessful?: () => void
  onValidationFailed?: (error?: string) => void
  validateOnFirstRender?: boolean
} & Omit<ComponentProps<typeof NumberField>, 'onError' | 'onerror'>

export const NumberFieldWithValidation: FC<Props> = ({
  validators,
  onValidationSuccessful,
  onValidationFailed,
  validateOnFirstRender,
  value,
  onChange,
  ...restOfProps
}) => {
  const [initialValidationComplete, setInitialValidationComplete] =
    useState<boolean>(false)

  // Run validation on first render
  if (
    validateOnFirstRender &&
    !initialValidationComplete &&
    typeof value !== 'undefined'
  ) {
    const validationResult = runValidation(value)
    handleValidationCallbacks(validationResult)
    setInitialValidationComplete(true)
  }

  function runValidation(value: number): ValidationResult {
    if (!validators) return { result: true, value }
    let validationResult: ValidationResult = { result: true, value }

    validators.every(e => {
      const isValid = e.validator(value)
      if (!isValid)
        validationResult = { result: false, errorMessage: e.errorMessage }
      return isValid
    })

    return validationResult
  }

  function handleOnChange(e: Event) {
    let value = (e.target as any).value
    if (Number.isNaN(value)) value = 0
    // @ts-expect-error
    const updatedEvent: Event = { ...e, target: { ...e.target, value } }
    onChange?.(updatedEvent)

    const validationResult = runValidation(value)
    handleValidationCallbacks(validationResult)
  }

  function handleValidationCallbacks(validationResult: ValidationResult) {
    validationResult.result
      ? onValidationSuccessful?.()
      : onValidationFailed?.(validationResult.errorMessage)
  }

  return (
    <NumberField
      className={styles['number-field']}
      hideStepper
      value={value}
      {...restOfProps}
      onChange={handleOnChange}
    />
  )
}
