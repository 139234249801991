import { InputErrorMessageType } from '@components/inputErrorMessage/InputErrorMessage'
import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { useTranslations } from 'use-intl'

export type AnimationType = 'turntable' | 'boomerang' | 'light'

type VideoExportContextType = {
  animationTypes: {
    label: string
    value: AnimationType
  }[]
  qualityOptions: {
    label: string
    value: number
    upscale: boolean
  }[]
  animationType: AnimationType
  quality: number
  duration: number
  rotations: number
  loops: number
  angle: number
  frameWidthError: InputErrorMessageType
  frameHeightError: InputErrorMessageType
  durationError: InputErrorMessageType
  rotationsError: InputErrorMessageType
  loopsError: InputErrorMessageType
  angleError: InputErrorMessageType
  isExportButtonDisabled: boolean

  setAnimationType: (animationType: AnimationType) => void
  setQuality: (quality: number) => void
  setDuration: (duration: number) => void
  setRotations: (rotations: number) => void
  setLoops: (rotations: number) => void
  setAngle: (rotations: number) => void
  setFrameWidthError: (error: InputErrorMessageType) => void
  setFrameHeightError: (error: InputErrorMessageType) => void
  setDurationError: (error: InputErrorMessageType) => void
  setRotationsError: (error: InputErrorMessageType) => void
  setLoopsError: (error: InputErrorMessageType) => void
  setAngleError: (error: InputErrorMessageType) => void
}

const VideoExportContext = createContext<VideoExportContextType | null>(null)

export function VideoExportProvider({ children }: PropsWithChildren) {
  const t = useTranslations()
  const [animationType, setAnimationType] = useState<AnimationType>('turntable')
  const [quality, setQuality] = useState<number>(1.0)
  const [duration, setDuration] = useState<number>(5)
  const [rotations, setRotations] = useState<number>(1)
  const [loops, setLoops] = useState<number>(1)
  const [angle, setAngle] = useState<number>(90)
  const [frameWidthError, setFrameWidthError] =
    useState<InputErrorMessageType>(null)
  const [frameHeightError, setFrameHeightError] =
    useState<InputErrorMessageType>(null)

  const [durationError, setDurationError] =
    useState<InputErrorMessageType>(null)
  const [rotationsError, setRotationsError] =
    useState<InputErrorMessageType>(null)
  const [loopsError, setLoopsError] = useState<InputErrorMessageType>(null)
  const [angleError, setAngleError] = useState<InputErrorMessageType>(null)

  const isExportButtonDisabled =
    Boolean(frameWidthError) ||
    Boolean(frameHeightError) ||
    Boolean(durationError) ||
    (Boolean(rotationsError) &&
      (animationType === 'turntable' || animationType === 'light')) ||
    (Boolean(loopsError) && animationType === 'boomerang') ||
    (Boolean(angleError) && animationType === 'boomerang')

  return (
    <VideoExportContext.Provider
      value={{
        animationTypes: [
          {
            label: t('studio:downloadMenu:mp4:turntable'),
            value: 'turntable'
          },
          {
            label: t('studio:downloadMenu:mp4:boomerang'),
            value: 'boomerang'
          },
          {
            label: t('studio:downloadMenu:mp4:light'),
            value: 'light'
          }
        ],
        qualityOptions: [
          {
            label: t('studio:downloadMenu:mp4:high'),
            value: 1.0,
            upscale: true
          },
          {
            label: t('studio:downloadMenu:mp4:medium'),
            value: 0.5,
            upscale: false
          },
          {
            label: t('studio:downloadMenu:mp4:low'),
            value: 0.3,
            upscale: false
          }
        ],
        animationType,
        quality,
        duration,
        rotations,
        loops,
        angle,
        frameWidthError,
        frameHeightError,
        durationError,
        rotationsError,
        loopsError,
        angleError,
        isExportButtonDisabled,
        setAnimationType,
        setQuality,
        setDuration,
        setRotations,
        setLoops,
        setAngle,
        setFrameWidthError,
        setFrameHeightError,
        setDurationError,
        setRotationsError,
        setLoopsError,
        setAngleError
      }}>
      {children}
    </VideoExportContext.Provider>
  )
}

export function useVideoExport() {
  const context = useContext(VideoExportContext) as VideoExportContextType
  if (typeof context === 'undefined')
    throw new Error('useVideoExport must be called within VideoExportProvider')

  return context
}
