import { FC, useState } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { Radio, RadioGroup } from 'ui'
import DialogForm from '@components/base/Dialog/DialogForm'
import Typography from '@components/base/Typography/Typography'
import ShareExportFooter from './ShareExportFooter'
import VideoPreviewButton from './VideoPreviewButton'
import VideoFrameSize from '@components/share/VideoFrameSize'
import VideoAnimationSettings from './VideoAnimationSettings'

import {
  CodecType,
  MediaIO,
  VideoAnimationParameters,
  makeBoomerangVideoAnimationParameters,
  makeLightingVideoAnimationParameters,
  makeTurnTableVideoAnimationParameters,
  makeVideoEncodingParameters
} from '@services/engine/MediaIO'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'
import { useVideoExport } from '@contexts/VideoExportContext'

const VideoExportForm: FC<{ projectName: string }> = ({ projectName }) => {
  const t = useTranslations()

  const {
    animationTypes,
    qualityOptions,
    isExportButtonDisabled,
    animationType,
    quality,
    duration,
    rotations,
    loops,
    angle,
    setAnimationType,
    setQuality
  } = useVideoExport()

  const minSizePreference = useMediumMinimumSizePreference()

  const { previewVideoEncoding } = MediaIO

  const frameSize = useSceneState('frameSize')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )

  const { setPropertyState } = useSceneActions()

  const [codecType, setCodecType] = useState<CodecType>('avc') // for now setting codec type not exposed

  /* Load parameters into encoder */
  function makeVideoAnimationParameters(): VideoAnimationParameters | null {
    var params: VideoAnimationParameters
    switch (animationType) {
      case 'turntable': {
        const p = makeTurnTableVideoAnimationParameters()
        p.duration = duration
        p.numberOfTurns = rotations
        params = p
        break
      }

      case 'boomerang': {
        const p = makeBoomerangVideoAnimationParameters()
        p.duration = duration
        p.numberOfBackAndForth = loops
        p.horizontalAngleAmplitude = angle
        params = p
        break
      }

      case 'light': {
        const p = makeLightingVideoAnimationParameters()
        p.duration = duration
        p.numberOfTurns = rotations
        params = p
        break
      }

      default:
        return null
    }

    return params
  }

  /* Define handlers */
  function handleAnimationTypeChange(e: any) {
    const selected = e.target?.selected
    if (selected) setAnimationType(selected)
  }

  function handleQualityChange(e: any) {
    const selected = e.target?.selected
    if (selected) setQuality(selected)
  }

  /* Preview */
  function handlePreview() {
    const animationParams = makeVideoAnimationParameters()
    if (!animationParams) {
      return
    }
    previewVideoEncoding(animationParams)
    animationParams.delete()
  }

  /* Encode and download */
  function handleExport() {
    if (!canvasAnimationStartedForRecording) {
      const animParams = makeVideoAnimationParameters()
      if (!animParams) return

      const encodingParams = makeVideoEncodingParameters()

      encodingParams.codecType = codecType
      encodingParams.width = frameSize.w
      encodingParams.height = frameSize.h
      encodingParams.quality = quality
      encodingParams.framesPerSecond = 30
      encodingParams.fileName = projectName
      encodingParams.shouldUpscale = false

      for (const q of qualityOptions) {
        if (q.value === quality) {
          encodingParams.shouldUpscale = q.upscale
          break
        }
      }

      setPropertyState({
        key: 'videoEncodingParameters',
        value: encodingParams
      })

      setPropertyState({
        key: 'videoAnimationParameters',
        value: animParams
      })

      setPropertyState({
        key: 'canvasAnimationStartedForRecording',
        value: true
      })
    }
  }

  return (
    <>
      <DialogForm>
        <div className="flex flex-row align-center justify-between">
          <Typography variant="h4">
            {t('studio:downloadMenu:mp4:video')}
          </Typography>
          <VideoPreviewButton onPreviewClick={handlePreview} />
        </div>
        <RadioGroup
          horizontal
          selected={animationType}
          change={handleAnimationTypeChange}>
          {animationTypes.map(({ label, value }) => (
            <Radio key={value} value={value} size={minSizePreference}>
              {label}
            </Radio>
          ))}
        </RadioGroup>
        <Typography variant="h4">
          {t('studio:downloadMenu:mp4:quality')}
        </Typography>
        <RadioGroup horizontal selected={quality} change={handleQualityChange}>
          {qualityOptions.map(({ label, value }) => (
            <Radio key={value} value={value} size={minSizePreference}>
              {label}
            </Radio>
          ))}
        </RadioGroup>
        <VideoAnimationSettings />
        <VideoFrameSize />
      </DialogForm>
      <ShareExportFooter
        onExportClick={handleExport}
        exportDisabled={isExportButtonDisabled}
        buttonText={t('studio:downloadMenu:mp4:renderAndDownloadButton')}
      />
    </>
  )
}

export default VideoExportForm
