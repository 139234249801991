import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { Presence, Channel } from 'phoenix'
import { EventChannel } from 'redux-saga'
import {
  receivedRemoteUserEmojiMessageEvent,
  receivedRemoteUserMouseMoveEvent,
  receivedRemoteUserLocationChangeEvent,
  sendLocalUserEmojiMessageValue,
  sendLocalUserMousePosition,
  sendLocalUserInputMessageValue,
  startRemoteUsersFollowSession,
  endRemoteUsersFollowSession,
  setRemoteUsersFollowingLocalUser,
  setLocalUserFollowingRemoteUser,
  receivedRemotePropertyChangeEvent,
  sendLocalUserSubmitMessageValue,
  receivedRemoteSceneChangeEvent,
  sendLocalUserPlayerChangeEvent,
  clearLocalMessages
} from '@store/slices/syncSlice'
import {
  createPresenceEventChannel,
  createSocketEventChannel
} from '@store/middleware/sync/eventChannels'
import {
  handleLocalUserInputActions,
  handleRemotePropertyChangeEvent,
  handleRemoteUserEmojiMessageEvent,
  handleRemoteUserMouseMoveEvent,
  handleRemoteUserPositionChangeEvent,
  registerLocalUserMouseMoveListener
} from '@store/middleware/sync/handlers'
import { RootState } from '@store/store'
import Context from '@store/middleware/context'
import createEventChannel from '../createEventChannel'
import {
  PropertyPayload,
  engineLoaded,
  setPropertyState
} from '@store/slices/sceneSlice'
import {
  EngineData,
  EngineDataOrigin,
  LocalUserLocation
} from '@services/engine/types'
import { isDocumentUpdatable } from '../document/documentSaga'
import { userLoaded } from '@store/slices/authSlice'
import {
  createSocket,
  socketCreated,
  watchSocket
} from '@store/middleware/socket/socketSaga'
import { loadProject } from '@store/slices/projectSlice'

function* handleEngineLoaded() {
  yield call(ensureUserLoaded)

  const projectUuid: string = yield select(
    (state: RootState) => state.project.projectUuid
  )

  const collaborationEnabled = Context.LDClient?.variation(
    'base-pf-collaboration'
  )

  if (collaborationEnabled) {
    yield put(createSocket({ socketName: 'sync', projectUuid }))
  }

  // yield take(
  //   action => action.type === socketCreated.type && action.payload === 'sync'
  // )

  // yield fork(handleSocketCreated)
}

function* handleSocketCreated() {
  const syncChannel: Channel = Context.sync.channel!

  const phoenixPresence: Presence = yield call(
    initializeSyncPresenceConnection,
    syncChannel!
  )

  // // TODO: enable this handler for frames
  yield call(initializeDomEventHandlers)

  yield all([
    call(watchRemoteUserSocketEvents, syncChannel!),
    call(watchRemotePresenceEvents, syncChannel!, phoenixPresence),
    call(watchLocalUserInputActions, syncChannel!),
    call(watchLocalUserLocation, syncChannel!),
    call(watchEngineData, syncChannel!),
    takeEvery(setPropertyState.type, handleSetPropertyState)
  ])
}

function* ensureUserLoaded() {
  // Sometimes, the user loads before engineLoaded() gets called
  const { localUser } = yield select((state: RootState) => state.auth)
  if (!localUser) yield take(userLoaded.type)
}

function* handleSetPropertyState(action: PayloadAction<PropertyPayload>) {
  const { auth, project, scene }: RootState = yield select(
    (state: RootState) => state
  )
  const { address } = scene
  const { localUser } = auth
  const { isFeatured, ownerUserUuid } = project

  // Prevent users who don't own the project to emit property changes to prevent project remote owner from receiving property changes and updating the original document
  if (!isDocumentUpdatable(localUser, isFeatured, ownerUserUuid)) {
    return
  }
  const payload = {
    address,
    property: { key: action.payload.key, value: action.payload.value }
  }
  Context.sync.channel?.push('property_change', payload)
}

function* watchLocalUserLocation(channel: Channel) {
  const localUser = yield select((state: RootState) => state.auth.localUser)
  const localUserLocationDataChannel: EventChannel<LocalUserLocation> =
    yield call(
      createEventChannel,
      Context.Engine?.EngineFrameDataChannel,
      'location_data'
    )

  while (true) {
    // get local user location & send to server
    const position: LocalUserLocation = yield take(localUserLocationDataChannel)
    if (position) {
      yield channel.push('location_change', position)
    }

    // read location of remote users & show 2D cursors
    const presenceList = yield select(
      (state: RootState) => state.sync.remoteUsers
    )
    presenceList.forEach((presenceState: any) => {
      if (presenceState.user.uuid !== localUser.uuid) {
        const position_2D = Context.Engine?.getRemoteUserCursorPosition(
          presenceState.user.uuid
        )

        // read back 2D pointer location for this user
        if (position_2D) {
          const posx = (((position_2D | 0) >> 0) & 0xffff) / 8.0
          const posy = (((position_2D | 0) >> 16) & 0xffff) / 8.0
          const height =
            document.getElementById('canvas')?.getClientRects()[0].height || 0

          const cursor = document.getElementById(
            `sync-cursor-${presenceState.user.uuid}`
          )
          if (cursor) {
            cursor.style.display = 'flex'
            cursor.style.setProperty('left', `${posx}px`)
            cursor.style.setProperty('top', `${posy - 0.04 * height}px`)
          }
        }
      }
    })
  }
}

function* watchLocalUserInputActions(channel: Channel) {
  while (true) {
    const action: PayloadAction = yield take([
      sendLocalUserMousePosition.type,
      sendLocalUserInputMessageValue.type,
      sendLocalUserSubmitMessageValue.type,
      sendLocalUserEmojiMessageValue.type,
      sendLocalUserPlayerChangeEvent.type
    ])
    yield fork(handleLocalUserInputActions, action, channel)
  }
}

function* watchRemoteUserSocketEvents(channel: Channel) {
  const socketEventChannel: EventChannel<PayloadAction> = yield call(
    createSocketEventChannel,
    channel
  )
  while (true) {
    const action: PayloadAction<any> = yield take(socketEventChannel)
    yield put(action)
  }
}

function* watchRemotePresenceEvents(channel: Channel, presence: Presence) {
  const localUser = yield select((state: RootState) => state.auth.localUser)

  const presenceEventChannel: EventChannel<PayloadAction> = yield call(
    createPresenceEventChannel,
    presence,
    channel,
    localUser
  )
  while (true) {
    const action: PayloadAction<any> = yield take(presenceEventChannel)
    yield put(action)
  }
}

function* watchEngineData(channel: Channel) {
  const dataChannel: EventChannel<EngineData> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'data'
  )
  while (true) {
    try {
      const dataEvent: EngineData = yield take(dataChannel)
      yield fork(handleEngineData, channel, dataEvent)
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleEngineData(channel: Channel, dataEvent: EngineData) {
  const { auth, project }: RootState = yield select((state: RootState) => state)

  const { localUser } = auth
  const { isFeatured, ownerUserUuid } = project

  const canUserUpdateDocument = isDocumentUpdatable(
    localUser,
    isFeatured,
    ownerUserUuid
  )

  // Prevent users who don't own the project to emit property changes to prevent project remote owner from receiving property changes and updating the original document
  if (
    dataEvent?.origin !== EngineDataOrigin.REMOTE_INPUT &&
    canUserUpdateDocument
  ) {
    channel.push('scene_change', { dataEvent: dataEvent })
  }
}

// TODO: enable this handler for frames
function* initializeDomEventHandlers() {
  yield call(registerLocalUserMouseMoveListener)
}

// TODO: refactor to align with the context
function initializeSyncPresenceConnection(channel: Channel) {
  const presence = new Presence(channel)
  return presence
}

function* handleStartRemoteUsersFollowSession() {
  const remoteUsers: RootState['sync']['remoteUsers'] = yield select(
    (state: RootState) =>
      state.sync.remoteUsers.filter(
        ({ user }) => user.uuid !== state.auth.localUser?.uuid
      )
  )

  yield put(setRemoteUsersFollowingLocalUser(remoteUsers))
  yield put(setLocalUserFollowingRemoteUser(null))
}

function* handleEndRemoteUsersFollowSession() {
  yield put(setRemoteUsersFollowingLocalUser([]))
}

function* handleProjectLoaded() {
  yield put(clearLocalMessages())
}

export default function* syncSaga() {
  yield all([
    takeEvery(engineLoaded.type, handleEngineLoaded),
    takeEvery(
      receivedRemoteUserMouseMoveEvent.type,
      handleRemoteUserMouseMoveEvent
    ),
    takeEvery(
      receivedRemoteUserLocationChangeEvent.type,
      handleRemoteUserPositionChangeEvent
    ),
    takeEvery(
      receivedRemotePropertyChangeEvent.type,
      handleRemotePropertyChangeEvent
    ),
    takeLeading(
      receivedRemoteUserEmojiMessageEvent.type,
      handleRemoteUserEmojiMessageEvent
    ),
    takeEvery(
      startRemoteUsersFollowSession.type,
      handleStartRemoteUsersFollowSession
    ),
    takeEvery(
      endRemoteUsersFollowSession.type,
      handleEndRemoteUsersFollowSession
    ),
    takeEvery(
      action => action.type === socketCreated.type && action.payload === 'sync',
      handleSocketCreated
    ),
    takeLatest(loadProject.type, handleProjectLoaded)
  ])
}
