import styles from '@styles/components/InputErrorMessage.module.scss'

export type InputErrorMessageType = string | null

type Props = {
  error: InputErrorMessageType
}

export function InputErrorMessage({ error }: Props) {
  if (!error) return null

  return <p className={styles['error']}>{error}</p>
}
