// The data structure returned from the Access Platform API
export type AccessProfileResponse = {
  asnp: {
    payload: string
  }
}

// The data structure of Access Profile
export type AccessProfile = {
  appProfile: AccessAppProfile
}

type AccessAppProfile = {
  accessibleItems?: AccessibleItem[]
}

/**
 * The data associated with an accessible item.
 */
type AccessibleItem = {
  status: AccessibleItemStatus
  fulfillable_items?: FulfillableItems
}

/**
 * The possible status values for an accessible item.
 */
export enum AccessibleItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

/**
 * A collection of info about fulfillable items that is keyed off of the fulfillable item code.
 */
type FulfillableItems = Record<string, FulfillableItem | undefined>

/**
 * The fulfillable item data that is associated with an accessible item.
 */
type FulfillableItem = {
  enabled: boolean

  charging_model?: {
    model?: string
    cap?: number
    unit?: string
    overage?: string
    rollover?: number
    levels?: number
  }
  feature_sets?: FeatureSets
  status_override_reason?: StatusOverrideReason
}

export type FeatureSets = Record<string, Feature | undefined>

type Feature = {
  id: string
  label: string
  enabled: boolean
}

enum StatusOverrideReason {
  LegalMisuseBlock = 'LEGAL_MISUSE_BLOCK'
}

/**
 * The entitlement payload
 */
export type Entitlement = {
  isFree: boolean
  isPaid: boolean
  isHardCapped: boolean
  isSoftCapped: boolean
  fireflyPlan: (typeof FireflyPlans)[number] | null
}

export const ENTITLEMENT_BLOCK: Entitlement = {
  isPaid: false,
  isFree: false,
  isHardCapped: false,
  isSoftCapped: false,
  fireflyPlan: null
}

// todo: refactor to union type
export enum FeatureKeys {
  BASIC = 'basic',
  PREMIUM = 'premium',
  HARD_CAPPED = 'hard_capped',
  SOFT_CAPPED = 'soft_capped',
  HARD_CAPPED_LIMIT_2 = 'hard_capped_limit_2', // 4.99 plan: single_app and hard_capped_limit_2
  K12 = 'k12',
  UNLIMITED = 'unlimited',
  STANDARD_CREDITS = 'standard_credits',
  PREMIUM_CREDITS = 'premium_credits',
  FIREFLY_PLAN_FREE = 'ff_lvl_00_free',
  FIREFLY_PLAN_SINGLE_APP = 'ff_lvl_10_single_app',
  FIREFLY_PLAN_ALL_APP = 'ff_lvl_20_all_apps',
  FIREFLY_PLAN_10 = 'ff_lvl_30_pro', // 9.99 plan
  FIREFLY_PLAN_30 = 'ff_lvl_40_video' // 29.99 plan
}

// A fulfillable item is a feature that an Adobe user subscribes to. It can also be considered a subscription plan. This is frequently referred to as an FI. Different apps can have different FIs for the same user.
export type FireflyFulfillableItemCreditModel =
  | 'UNAVAILABLE'
  | 'FREE-EXHAUSTABLE'
  | 'PAID-UNEXHAUSTABLE'
  | 'PAID-EXHAUSTABLE'

const FireflyPlans = [
  'FF_FREE',
  'FF_SINGLE',
  'FF_ALL',
  'FF_5',
  'FF_10',
  'FF_30'
] as const
