import { FC } from 'react'
import {
  ActionButton,
  FieldLabel,
  Icon,
  MenuItem,
  OverlayTrigger,
  Picker,
  Tooltip
} from 'ui'

import { useSceneActions, useSceneState } from '@hooks/useScene'
import Typography from '@components/base/Typography/Typography'
import LockIcon from '/public/s2_icon_lock.svg'
import LockOpenIcon from '/public/s2_icon_lock_open.svg'
import { useTranslations } from 'use-intl'
import { NumberFieldWithValidation } from '@components/NumberField/NumberFieldWithValidation'
import { useVideoExport } from '@contexts/VideoExportContext'
import {
  InputErrorMessage,
  InputErrorMessageType
} from '@components/inputErrorMessage/InputErrorMessage'

const VideoFrameSize: FC = () => {
  const t = useTranslations()
  const frameSizeLocked = useSceneState('frameSizeLocked')
  const frameSize = useSceneState('frameSize')
  const { setPropertyState } = useSceneActions()
  const minDimension = 16 // min width and min height
  const maxDimension = 32766 // max width and max height
  const maxPixels = 2073600 // equal to 1920 x 1080 - a limitation of the codec being used
  const {
    frameWidthError,
    frameHeightError,
    setFrameWidthError,
    setFrameHeightError
  } = useVideoExport()

  // Both dimensions must be validated even when only one of the dimensions is changing
  // due to the max pixels validator being dependent on both dimensions
  const validators = [
    {
      validator: () => frameSize.w >= minDimension,
      errorMessage: t('studio:downloadMenu:mp4:dimensionMinSize', {
        dimension: 'Width',
        min: minDimension
      })
    },
    {
      validator: () => frameSize.h >= minDimension,
      errorMessage: t('studio:downloadMenu:mp4:dimensionMinSize', {
        dimension: 'Height',
        min: minDimension
      })
    },
    {
      validator: () => frameSize.w <= maxDimension,
      errorMessage: t('studio:downloadMenu:mp4:dimensionMaxSize', {
        dimension: 'Width',
        max: maxDimension
      })
    },
    {
      validator: () => frameSize.h <= maxDimension,
      errorMessage: t('studio:downloadMenu:mp4:dimensionMaxSize', {
        dimension: 'Height',
        max: maxDimension
      })
    },
    {
      validator: () => frameSize.w % 2 === 0,
      errorMessage: t('studio:downloadMenu:mp4:dimensionMustBeEven', {
        dimension: 'Width'
      })
    },
    {
      validator: () => frameSize.h % 2 === 0,
      errorMessage: t('studio:downloadMenu:mp4:dimensionMustBeEven', {
        dimension: 'Height'
      })
    },
    {
      validator: () => frameSize.h * frameSize.w <= maxPixels,
      errorMessage: t('studio:downloadMenu:mp4:maxPixelsExceeded', {
        maxPixels: parseFloat(maxPixels.toString()).toLocaleString('en')
      })
    }
  ]

  // Makes sure to show only one error at a time, prioritizing the width error first
  function renderErrorMessages(
    widthError: InputErrorMessageType,
    heightError: InputErrorMessageType
  ) {
    if (widthError) {
      return <InputErrorMessage error={widthError} />
    }

    if (heightError) {
      return <InputErrorMessage error={heightError} />
    }
  }

  function updateDimension(dimension: 'width' | 'height', value: number) {
    const updatedFrameSize: typeof frameSize =
      dimension === 'width'
        ? { w: value, h: frameSize.h }
        : { w: frameSize.w, h: value }

    setPropertyState({
      key: 'frameSize',
      value: updatedFrameSize
    })
  }

  function handleValidationSuccessful() {
    setFrameWidthError(null)
    setFrameHeightError(null)
  }

  return (
    <div className="flex flex-col">
      <Typography variant="h4">{t('studio:downloadMenu:mp4:size')}</Typography>
      <div className="flex align-end gap-xxs">
        <div className="flex flex-col">
          <FieldLabel for="video-frame-size-width">
            {t('studio:downloadMenu:mp4:width')}
          </FieldLabel>

          <NumberFieldWithValidation
            aria-label="video-frame-size-width"
            name="Width"
            disabled={frameSizeLocked}
            step={1}
            value={frameSize.w}
            validators={validators}
            validateOnFirstRender
            onInput={e => updateDimension('width', (e.target as any).value)}
            onChange={e => updateDimension('width', (e.target as any).value)}
            onValidationSuccessful={handleValidationSuccessful}
            onValidationFailed={error => setFrameWidthError(error as string)}
          />
        </div>
        <div className="flex flex-col">
          <FieldLabel for="video-frame-size-height">
            {t('studio:downloadMenu:mp4:height')}
          </FieldLabel>
          <NumberFieldWithValidation
            aria-label="video-frame-size-height"
            name="Height"
            disabled={frameSizeLocked}
            step={1}
            value={frameSize.h}
            validators={validators}
            validateOnFirstRender
            onInput={e => updateDimension('height', (e.target as any).value)}
            onChange={e => updateDimension('height', (e.target as any).value)}
            onValidationSuccessful={handleValidationSuccessful}
            onValidationFailed={error => setFrameHeightError(error as string)}
          />
        </div>
        <div className="flex flex-col">
          <Picker style={{ width: 60 }} value="px" disabled>
            <MenuItem key="random" value="px">
              {t('scene:properties:frame:unit:pixels')}
            </MenuItem>
          </Picker>
        </div>

        <div className="flex flex-col justify-end">
          <OverlayTrigger placement="top" offset={0}>
            <ActionButton
              quiet
              className="action-btn-m"
              slot="trigger"
              onClick={() =>
                setPropertyState({
                  key: 'frameSizeLocked',
                  value: !frameSizeLocked
                })
              }
              selected={frameSizeLocked}
              aria-label="repeat type none">
              <Icon slot="icon" className="icon-m">
                {frameSizeLocked ? <LockIcon /> : <LockOpenIcon />}
              </Icon>
            </ActionButton>
            <Tooltip slot="hover-content">
              {t('studio:downloadMenu:mp4:lockAspectRatio')}
            </Tooltip>
          </OverlayTrigger>
        </div>
      </div>
      {renderErrorMessages(frameWidthError, frameHeightError)}
    </div>
  )
}

export default VideoFrameSize
