import {
  CreateAnonymousUserDocument,
  CreateAnonymousUserMutationResult,
  CreateProjectMutationResult,
  CreateUserDocument,
  CreateUserMutationResult,
  GetUserByAdobeIdDocument,
  GetUserByAdobeIdQueryHookResult,
  Project,
  UserOriginEnum,
  UpdateUserDocument,
  UpdateUserMutationResult,
  User,
  CreateProjectDocument
} from '../../graphql/__generated__/schema'
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import ApolloClient from '@store/graphql/client'
import {
  setLocalUser,
  dispatchAuthStrategy,
  setStatus,
  userLoaded,
  logout,
  setIsAuthenticationStateLoaded,
  setShowPrivateBetaSurveyToast,
  setShowSignUpSignInModal,
  promptLogin,
  setAuthToken
} from '@store/slices/authSlice'
import AdobeIMS from '@services/auth/IMS'
import { AdobeImsProfile } from 'types/adobeIms'
import Context, { sendDunamisStartEvent } from '../context'
import { retrieveAdobeUserAvatarByAdobeUserId } from '@services/avatar'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from '@constants/localStorage'
import { BasePfProjectsPrivateDefaultFlagValue } from 'types/flag'
import { PayloadAction } from '@reduxjs/toolkit'
import { createNewProjectName } from '@hooks/useCreateProject'
import {
  loadProject,
  setIsFeatured,
  setIsPublic,
  setName,
  setOwnerUserUuid,
  setProjectUuid
} from '@store/slices/projectSlice'
import { setEngineState } from '@store/slices/sceneSlice'
import { LDContext } from 'launchdarkly-js-client-sdk'
import { updateLDContext } from '@store/slices/launchDarklySlice'
import { getUserLocalePreference } from '@concerns/i18n/browser'
import { loadFireflyFulfillableItemCreditModel } from '@store/slices/fireflySlice'

export const HasViewedPrivateBetaSurveyMessage =
  'has_viewed_private_beta_survey_message'

export type AuthStrategy = (...args: any[]) => Generator

function showPrivateBetaSurveyToast(user: User) {
  if (!Context.LDClient?.variation('base-tf-ui-private-beta-survey')) {
    return false
  }

  if (!user.insertedAt) {
    return false
  }

  if (
    localStorage.getItem(LocalStorageKey.privateBetaHasViewedSurveyMessage) ===
    LocalStorageBooleanValue.TRUE
  ) {
    return false
  }

  const currentDate = Number(new Date())
  const userCreatedDate = Number(new Date(user.insertedAt))
  const oneDay = 24 * 60 * 60 * 1000
  const hasOneDayElapsed = currentDate - userCreatedDate > oneDay
  return hasOneDayElapsed
}

function* loadUser(user: User) {
  yield fork(setLaunchDarklyAuthenticatedUserContext, user)
  yield put(setLocalUser(user))
  yield put(loadFireflyFulfillableItemCreditModel())
  yield put(userLoaded())
}

export function* createSceneToImageProject(user: User) {
  const projectsPrivateDefault = Boolean(
    user?.preferences?.projectsPrivateDefault
  )

  const createProjectResult: CreateProjectMutationResult =
    yield ApolloClient.mutate<CreateProjectMutationResult>({
      mutation: CreateProjectDocument,
      variables: {
        name: createNewProjectName(),
        tags: ['lifestyle', 'tech'],
        public: projectsPrivateDefault
      }
    })

  const project = createProjectResult.data?.createProject
  return project
}

function* setProjectState(project: Project) {
  yield put(setProjectUuid(project!.uuid as string))
  yield put(setIsFeatured(project?.featured as boolean))
  yield put(setIsPublic(project?.public as boolean))
  yield put(setName(project!.name as string))
  yield put(setOwnerUserUuid(project?.ownerUserUuid || null))
}

function* authenticateAdobeUser(
  userOrigin: UserOriginEnum
): Generator<any, User | null, any> {
  let user: User | null = null

  if (AdobeIMS.isSignedInUser()) {
    user = yield call(handleAdobeUser, userOrigin)
  }

  if (user) {
    yield call(loadUser, user)
    yield put(setStatus('AUTHENTICATED'))
  }

  yield put(setIsAuthenticationStateLoaded(true))
  return user
}

export function* sceneToImageAuthStrategy(): Generator<any, User | null, any> {
  yield put(setStatus('CHECKING_STATUS'))
  const user: User | null = yield call(
    authenticateAdobeUser,
    UserOriginEnum.Firefly
  )

  if (user) {
    const project: Project = yield createSceneToImageProject(user)
    yield call(setProjectState, project)
    yield put(setEngineState('INITIALIZE'))
    yield put(
      updateLDContext({
        email: user.adobeUserEmail as string,
        key: user.adobeUserEmail as string
      })
    )
    yield put(loadProject({ requireAuth: true }))
  } else {
    yield call(clearAuthenticationState)
    yield put(promptLogin({}))
  }

  return user
}

export function* defaultAuthStrategy(): Generator<any, User | null, any> {
  yield put(setStatus('CHECKING_STATUS'))
  let user: User | null = yield call(
    authenticateAdobeUser,
    UserOriginEnum.Default
  )

  if (!user) {
    if (Context.LDClient?.allFlags()['base-tf-fx-authentication-anonymous']) {
      user = yield call(handleAnonymousUser)
    }
  }

  if (user) {
    yield put(
      updateLDContext({
        email: user.adobeUserEmail as string,
        key: user.adobeUserEmail as string
      })
    )
    yield call(loadUser, user)
    yield put(setStatus('AUTHENTICATED'))

    const tokenInfo = AdobeIMS.getAccessToken()
    if (tokenInfo?.token) {
      yield put(setAuthToken(tokenInfo.token))
    }

    if (showPrivateBetaSurveyToast(user)) {
      yield put(setShowPrivateBetaSurveyToast(true))
    }
  } else {
    yield call(clearAuthenticationState)
  }

  yield put(setIsAuthenticationStateLoaded(true))
  return user
}

export function* handleDispatchAuthStrategy({
  payload: authStrategy
}: PayloadAction<AuthStrategy>) {
  yield call(authStrategy)
}

async function updateUserProjectPrivateDefault(user: User) {
  const hasNotSetProjectPrivateDefault =
    typeof user?.preferences!.projectsPrivateDefault !== 'boolean'

  if (hasNotSetProjectPrivateDefault) {
    const updateUser = await ApolloClient.mutate<
      UpdateUserMutationResult['data']
    >({
      mutation: UpdateUserDocument,
      variables: {
        preferences: {
          projectsPrivateDefault: getProjectsPrivateDefaultValue(
            await AdobeIMS.getProfile()
          )
        }
      }
    })

    if (updateUser.data?.user) {
      user = updateUser.data?.user
    }
  }

  return user
}

export function* setLaunchDarklyAuthenticatedUserContext(user: User) {
  const userContext: LDContext = {
    key: user.adobeUserEmail as string,
    email: user.adobeUserEmail as string
  }

  // This only sets the context for the non-react version of the LD client!
  // Our LD integration needs to be refactored so that we only have one client
  // across the entire app
  yield Context.LDClient?.identify(userContext)
}

export async function handleAdobeUser(userOrigin: UserOriginEnum) {
  let existingAdobeUser = await getAdobeUser()
  if (existingAdobeUser) {
    existingAdobeUser = await updateUserProjectPrivateDefault(existingAdobeUser)
    const userWithLatestProfile = await updateLocalUserProfile(
      existingAdobeUser
    )
    return userWithLatestProfile
  } else {
    const newAdobeUser = await createAdobeUser(userOrigin)
    return newAdobeUser
  }
}

export async function handleAnonymousUser() {
  // TODO: Use a different strategy for preserving anonymous sessions
  // const existingAnonymousUser = await getAnonymousUserByToken()
  const existingAnonymousUser = false
  if (existingAnonymousUser) {
    return existingAnonymousUser
  } else {
    const newAnonymousUser = await createAnonymousUser()
    return newAnonymousUser
  }
}

async function getAdobeUser() {
  const adobeProfile: AdobeImsProfile = await AdobeIMS.getProfile()
  const getUserByAdobeId = await ApolloClient.query<
    GetUserByAdobeIdQueryHookResult['data']
  >({
    fetchPolicy: 'network-only',
    query: GetUserByAdobeIdDocument,
    variables: {
      adobeUserId: adobeProfile.userId
    }
  })

  await sendDunamisStartEvent(
    getUserByAdobeId?.data?.user?.uuid!,
    getUserByAdobeId?.data?.user?.adobeUserId!
  )

  return getUserByAdobeId?.data?.user
}

async function createAdobeUser(userOrigin: UserOriginEnum) {
  const adobeProfile: AdobeImsProfile = await AdobeIMS.getProfile()
  const userAvatar = await retrieveAdobeUserAvatarByAdobeUserId(
    adobeProfile.userId
  )

  BasePfProjectsPrivateDefaultFlagValue.USE_PAID_SUBSCRIPTION_STATUS

  const response = await ApolloClient.mutate<CreateUserMutationResult['data']>({
    mutation: CreateUserDocument,
    variables: {
      adobeUserId: adobeProfile.userId,
      adobeUserDisplayName: adobeProfile.displayName,
      adobeUserEmail: adobeProfile.email,
      adobeUserAvatarUrl: userAvatar,
      preferences: {
        projectsPrivateDefault: getProjectsPrivateDefaultValue(adobeProfile)
      },
      origin: userOrigin
    }
  })

  const adobeUserEmail = response?.data?.createUser?.adobeUserEmail
  const user = response?.data?.createUser?.user

  if (user && adobeUserEmail) {
    user.adobeUserEmail = adobeUserEmail
  }

  return user
}

function getProjectsPrivateDefaultValue(adobeProfile: AdobeImsProfile) {
  const flag: BasePfProjectsPrivateDefaultFlagValue =
    Context.LDClient?.variation('base-pf-private-project-default-value')

  if (flag === BasePfProjectsPrivateDefaultFlagValue.PUBLIC) {
    return true
  } else if (flag === BasePfProjectsPrivateDefaultFlagValue.PRIVATE) {
    return false
  }

  // If the user is a paid user then their projects should be private by default
  const isPaidUser = adobeProfile.account_type !== 'type1'
  return !isPaidUser
}

async function createAnonymousUser() {
  const createAnonymousUser = await ApolloClient.mutate<
    CreateAnonymousUserMutationResult['data']
  >({ mutation: CreateAnonymousUserDocument })

  return createAnonymousUser?.data?.anonymousUser
}

export function* clearAuthenticationState() {
  yield put(setLocalUser(null))
  yield put(setStatus('UNAUTHENTICATED'))
}

export function* handleLogout() {
  yield put(setIsAuthenticationStateLoaded(false))
  yield call(clearAuthenticationState)
  yield AdobeIMS.signOut({
    redirect_uri: `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}`
  })
}

async function updateLocalUserProfile(user: User) {
  const { adobeUserId, adobeUserAvatarUrl } = user
  const updatedAvatar = await retrieveAdobeUserAvatarByAdobeUserId(adobeUserId)

  if (updatedAvatar === adobeUserAvatarUrl) return user

  const updatedUser = await ApolloClient.mutate<
    UpdateUserMutationResult['data']
  >({
    mutation: UpdateUserDocument,
    variables: {
      adobeUserAvatarUrl: updatedAvatar
    }
  })

  return updatedUser.data?.user || user
}

function* handlePromptLogin({
  payload
}: PayloadAction<{ onAuthenticated?: (...args: any[]) => void }>) {
  const susiLightEnabled = Context.LDClient?.variation('base-pf-ui-login-modal')

  susiLightEnabled
    ? ((yield put(setShowSignUpSignInModal(true))) as Generator)
    : AdobeIMS.signIn({
        locale: getUserLocalePreference(),
        redirect_uri: window.location.href
      })

  yield take(
    (action: any) =>
      action.type === setStatus.type && action.payload === 'AUTHENTICATED'
  )

  if (typeof payload.onAuthenticated !== 'undefined') {
    yield call(payload.onAuthenticated)
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(dispatchAuthStrategy.type, handleDispatchAuthStrategy),
    takeLatest(logout.type, handleLogout),
    takeLatest(promptLogin.type, handlePromptLogin)
  ])
}
