import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styles from '@styles/components/SceneNavigation.module.scss'
import {
  ActionButton,
  ActionMenu,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  OverlayCloseEvent,
  Popover,
  SceneNavigation as SceneNavigationUI,
  Tooltip
} from 'ui'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import {
  ElementIconId,
  EngineBlendType,
  EngineSelectedSceneNode,
  EngineStackReorderingMode
} from '@services/engine/types'
import { useSelection } from '@services/engine/useSelection'
import { setOpenedPanel, StudioPanel } from '@store/slices/projectSlice'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { STUDIO_CONTAINER_ID } from '@components/studio/Studio'

import ArrowIcon from '/public/s2_icon_arrow.svg'
import MoreIcon from '/public/s2_icon_more.svg'
import ObjectPropertiesIcon from '/public/s2_icon_properties.svg'
import DeleteIcon from '/public/s2_icon_delete.svg'
import DuplicateIcon from '/public/s2_icon_duplicate.svg'
import VisibilityIcon from '/public/s2_icon_visibility.svg'
import VisibilityOffIcon from '/public/s2_icon_visibility_off.svg'
import OrderTopIcon from '/public/s2_icon_order_top.svg'
import OrderUpIcon from '/public/s2_icon_order_up.svg'
import GroupIcon from '/public/s2_icon_group.svg'
import UngroupIcon from '/public/s2_icon_ungroup.svg'
import { useTranslations } from 'use-intl'

export type BlendType = 'add' | 'subtract' | 'intersect' | 'color'
export type PrimitiveType = 'SoftBox' | 'Horseshoe' | 'Triangle' | 'Star'

export interface Primitive {
  id: string
  disabled?: boolean
  color?: string
  hasBlending?: boolean
  blendType: BlendType
  isSelected?: boolean
  isVisible?: boolean
  type: PrimitiveType
  isHovering?: boolean
}

export const UIElementIdMap = {
  [ElementIconId.GROUP]: 'Group',
  [ElementIconId.CUBE]: 'SoftBox',
  [ElementIconId.CYLINDER]: 'Cylinder',
  [ElementIconId.SPHERE]: 'Sphere',
  [ElementIconId.CAPSULE]: 'Capsule',
  [ElementIconId.CONE]: 'Cone',
  [ElementIconId.TORUS]: 'Torus',
  [ElementIconId.U]: 'Horseshoe',
  [ElementIconId.DROP]: 'Drop',
  [ElementIconId.TRIANGLE]: 'Triangle',
  [ElementIconId.CONE_DUPE]: 'Cone',
  [ElementIconId.STAR]: 'Star',
  [ElementIconId.POLYGON]: 'Polygon',
  [ElementIconId.EGG]: 'Drop', // Temporary until we figure out how to get an egg icon for this
  [ElementIconId.PIN]: 'Drop', // Temporary until we figure out how to get a pin icon for this
  [ElementIconId.TEXT]: 'Text',
  [ElementIconId.CURVES]: 'Curves'
} as const

export const UIBlendMap = {
  [EngineBlendType.ADD]: 'add',
  [EngineBlendType.SUBTRACT]: 'subtract',
  [EngineBlendType.INTERSECT]: 'intersect',
  [EngineBlendType.COLOR]: 'color',
  [EngineBlendType.REPEL]: 'repel',
  [EngineBlendType.AVOID]: 'avoid'
} as const

type SelectEvent = CustomEvent<{ id: string }>

type ReorderEvent = CustomEvent<{ targetIds: Array<string>; to: number }>

enum ContextmenuItem {
  SHOW_PROPERTIES = 'scene-navigation-contextmenu-item-show-properties',
  GROUP = 'scene-navigation-contextmenu-item-group',
  UNGROUP = 'scene-navigation-contextmenu-item-ungroup',
  DUPLICATE = 'scene-navigation-contextmenu-item-duplicate',
  DELETE = 'scene-navigation-contextmenu-item-delete',
  TOGGLE_LOCK = 'scene-navigation-contextmenu-item-toggle-lock',
  TOGGLE_VISIBILITY = 'scene-navigation-contextmenu-item-toggle-visibility',
  MOVE_UP_ONE = 'scene-navigation-contextmenu-item-move-up-one',
  MOVE_DOWN_ONE = 'scene-navigation-contextmenu-item-move-down-one',
  MOVE_TOP = 'scene-navigation-contextmenu-item-move-top',
  MOVE_BOTTOM = 'scene-navigation-contextmenu-item-move-bottom'
}

const SceneNavigation: React.FC = () => {
  const t = useTranslations()
  const elements = useSceneState('elements')
  const selectedSceneNode = useSceneState('selectedSceneNode')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )

  const {
    deletePrimitive,
    duplicatePrimitive,
    goDownGroupHierarchy,
    goUpGroupHierarchy,
    mouseEnterObject,
    mouseLeaveObject,
    reorderStackElements,
    selectElement,
    togglePrimitiveVisibility
  } = useSceneActions()

  const sizePreference = useProjectState('sizePreference')
  const buttonSize = sizePreference === 'l' ? 'm' : 's'
  const menuSize = useMediumMinimumSizePreference()

  const { setShowSceneNavigator } = useProjectActions()

  const flags = useFlags()

  const [dragging, setDragging] = useState(false)

  const { canGroup, group, canUngroup, ungroup } = useSelection()

  const groupMenuEnabled = canGroup()
  const ungroupMenuEnabled = canUngroup()

  const isObjectSelected =
    selectedSceneNode === EngineSelectedSceneNode.SHAPE ||
    selectedSceneNode === EngineSelectedSceneNode.GROUP ||
    selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED

  if (canvasAnimationStartedForRecording) return null

  const normalizedElements = elements
    .map(
      element =>
        ({
          id: element.uuid,
          color: '#' + element.color.toString(16).padStart(6, '0'),
          hasBlending: element.blend > 0,
          isSelected: element.selected,
          isVisible: element.visible,
          isHovering: element.hovered,
          blendType: UIBlendMap[element.combine],
          type: UIElementIdMap[element.iconID]
          // @TODO: Need to pass the element.name to t()
          // name: t(`scene:layer:element:${element.name}`)
          // Hard to know exactly what values are coming through since they are user-created
          // https://adobe-3di.slack.com/archives/C03KEC69HH6/p1733510641862579
          // https://git.corp.adobe.com/BRIO/neo/issues/3244
        } as Primitive)
    )
    .reverse()

  const selectedElements = normalizedElements.filter(
    ({ isSelected }) => isSelected
  )

  const handleDoubleClick = (event: CustomEvent<{ targetId: string }>) => {
    selectElement({ uuid: event.detail.targetId, multiSelect: false })
    goDownGroupHierarchy(event.detail.targetId)
  }

  const handleBack = () => {
    if (
      selectedSceneNode === EngineSelectedSceneNode.PROJECT ||
      selectedSceneNode === EngineSelectedSceneNode.NONE
    )
      return

    // @TODO: Remove this guard once we are able to drag elements between groups
    if (!dragging && selectedSceneNode) {
      goUpGroupHierarchy()
    }
  }

  const handleDragHoverTimeout = (
    event: CustomEvent<{ activeId: string; targetId: string }>
  ) => {
    const { activeId, targetId } = event.detail
  }

  const handleSelect = (event: SelectEvent) => {
    selectElement({ uuid: event.detail.id, multiSelect: false })
    document.getElementById('canvas')?.focus()
  }

  const handleMultiSelect = (event: SelectEvent) => {
    selectElement({ uuid: event.detail.id, multiSelect: true })
    document.getElementById('canvas')?.focus()
  }

  const handleReorder = (event: ReorderEvent) => {
    const { targetIds, to } = event.detail
    const srcIndices = targetIds
      .map(id => elements.findIndex(element => element.uuid === id))
      .reverse()

    const destIndex = elements.findIndex(
      element => element.uuid === normalizedElements[to].id
    )

    const mode = srcIndices.some(index => index > destIndex)
      ? EngineStackReorderingMode.BEFORE
      : EngineStackReorderingMode.AFTER

    return reorderStackElements({
      srcIndices,
      destIndex,
      mode
    })
  }

  const handleMoveSelectedObjects = (
    direction: 'up-one' | 'down-one' | 'top' | 'bottom'
  ) => {
    const targetIds = normalizedElements
      .filter(({ isSelected }) => isSelected)
      .map(({ id }) => id)

    const currentIndexes = targetIds
      .map(targetId =>
        normalizedElements.findIndex(({ id }) => id === targetId)
      )
      .filter(idIndex => idIndex !== -1)
      .sort((a, b) => a - b)
    if (!currentIndexes.length) return

    let to: number

    if (direction === 'top') {
      to = 0
    } else if (direction === 'bottom') {
      to = normalizedElements.length - 1
    } else if (direction === 'down-one') {
      const lastObjectCurrentIndex = currentIndexes[currentIndexes.length - 1]

      if (lastObjectCurrentIndex === normalizedElements.length - 1) {
        // Target index out of bounds
        return
      }
      to = lastObjectCurrentIndex + 1
    } else {
      const firstObjectCurrentIndex = currentIndexes[0]

      if (firstObjectCurrentIndex === 0) {
        // Target index out of bounds
        return
      }
      to = firstObjectCurrentIndex - 1
    }

    handleReorder({ detail: { targetIds, to } } as ReorderEvent)
  }

  const handleContextMenu = async (
    event: CustomEvent<{ id: string; originalEvent: PointerEvent }>
  ) => {
    const { id, originalEvent } = event.detail
    const rect = originalEvent?.target?.getBoundingClientRect?.()
    if (!rect || !originalEvent?.target) return

    if (!selectedElements.some(el => el.id === id)) {
      handleSelect({ detail: { id } } as SelectEvent)
    }

    const popoverEl = document.getElementById(
      'scene-navigation-contextmenu-popover'
    )
    const studioContainerEl = document.getElementById(STUDIO_CONTAINER_ID)
    if (!popoverEl || !studioContainerEl) return

    // Require to import @spectrum-web-components package on runtime because package uses browser api
    const { VirtualTrigger, openOverlay } = await import(
      '@spectrum-web-components/overlay'
    )

    const { x, y } = rect
    const virtualTrigger = new VirtualTrigger(
      x + 40,
      isObjectSelected ? y : y + 26
    )

    const overlay = await openOverlay(popoverEl, {
      trigger: virtualTrigger,
      placement: 'right-start',
      offset: 8,
      notImmediatelyClosable: true,
      type: 'auto'
    })

    studioContainerEl.insertAdjacentElement('afterend', overlay)
    document.getElementById('canvas')?.focus()
  }

  const handleContextmenuChange = (e: Event) => {
    e?.currentTarget.dispatchEvent(OverlayCloseEvent)
    document.getElementById('canvas')?.focus()

    switch (e?.target?.value as ContextmenuItem) {
      case ContextmenuItem.SHOW_PROPERTIES:
        return setOpenedPanel(StudioPanel.Properties)
      case ContextmenuItem.GROUP:
        return group()
      case ContextmenuItem.UNGROUP:
        return ungroup()
      case ContextmenuItem.DUPLICATE:
        return duplicatePrimitive()
      case ContextmenuItem.DELETE:
        return deletePrimitive()
      case ContextmenuItem.TOGGLE_VISIBILITY:
        return toggleSelectedPrimitivesVisibility()
      case ContextmenuItem.MOVE_TOP:
        return handleMoveSelectedObjects('top')
      case ContextmenuItem.MOVE_UP_ONE:
        return handleMoveSelectedObjects('up-one')
      case ContextmenuItem.MOVE_DOWN_ONE:
        return handleMoveSelectedObjects('down-one')
      case ContextmenuItem.MOVE_BOTTOM:
        return handleMoveSelectedObjects('bottom')
      default:
        break
    }
  }

  const toggleSelectedPrimitivesVisibility = () => {
    selectedElements.forEach(el => {
      togglePrimitiveVisibility({ uuid: el.id })
    })
  }

  const handleItemMouseOver = (event: CustomEvent<{ id: string }>) => {
    mouseEnterObject(event.detail.id)
  }

  const handleItemMouseLeave = (event: CustomEvent<{ id: string }>) => {
    mouseLeaveObject(event.detail.id)
  }

  if (!normalizedElements.length) return null
  return (
    <>
      {/* Required to wrap Popover with a div element because SWC Overlay controls remove/adding popover from the dom and React requires a root element rendered in both the real DOM and virtual DOM */}
      <div>
        <Popover id="scene-navigation-contextmenu-popover">
          <CursorOverlay />
          <Menu onchange={handleContextmenuChange} size={menuSize}>
            <MenuItem value={ContextmenuItem.SHOW_PROPERTIES}>
              <Icon slot="icon">
                <ObjectPropertiesIcon />
              </Icon>
              {t('scene:layers:properties')}
            </MenuItem>

            {groupMenuEnabled && (
              <MenuItem value={ContextmenuItem.GROUP}>
                <Icon slot="icon">
                  <GroupIcon />
                </Icon>
                {t('scene:layers:group')}
              </MenuItem>
            )}

            {ungroupMenuEnabled && (
              <MenuItem value={ContextmenuItem.UNGROUP}>
                <Icon slot="icon">
                  <UngroupIcon />
                </Icon>
                {t('scene:layers:ungroup')}
              </MenuItem>
            )}

            <MenuDivider size="s" />

            <MenuItem value={ContextmenuItem.DUPLICATE}>
              <Icon slot="icon">
                <DuplicateIcon />
              </Icon>
              {t('scene:layers:duplicate')}
            </MenuItem>
            <MenuItem value={ContextmenuItem.DELETE}>
              <Icon slot="icon">
                <DeleteIcon />
              </Icon>
              {t('scene:layers:delete')}
            </MenuItem>
            <MenuDivider size="s" />
            {selectedElements.length && (
              <MenuItem value={ContextmenuItem.TOGGLE_VISIBILITY}>
                <Icon slot="icon">
                  {selectedElements[0].isVisible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </Icon>
                {selectedElements.length > 1
                  ? t('scene:layers:visibility')
                  : selectedElements[0].isVisible
                  ? t('scene:layers:visibility:hide')
                  : t('scene:layers:visibility:show')}
              </MenuItem>
            )}
            <MenuDivider size="s" />
            <MenuItem value={ContextmenuItem.MOVE_TOP}>
              <Icon slot="icon">
                <OrderTopIcon />
              </Icon>
              {t('scene:layers:move:top')}
            </MenuItem>
            <MenuItem value={ContextmenuItem.MOVE_UP_ONE}>
              <Icon slot="icon">
                <OrderUpIcon />
              </Icon>
              {t('scene:layers:move:up')}
            </MenuItem>
            <MenuItem value={ContextmenuItem.MOVE_DOWN_ONE}>
              <Icon slot="icon">
                <OrderUpIcon style={{ transform: 'scale(1, -1)' }} />
              </Icon>
              {t('scene:layers:move:down')}
            </MenuItem>
            <MenuItem value={ContextmenuItem.MOVE_BOTTOM}>
              <Icon slot="icon">
                <OrderTopIcon style={{ transform: 'scale(1, -1)' }} />
              </Icon>
              {t('scene:layers:move:bottom')}
            </MenuItem>
          </Menu>
        </Popover>
      </div>

      <div className={styles['container']}>
        <SceneNavigationUI
          onContextMenu={handleContextMenu}
          onDrag={() => setDragging(true)}
          onDragEnd={() => setDragging(false)}
          primitives={normalizedElements}
          onDoubleClick={handleDoubleClick}
          onBack={handleBack}
          onDragHoverTimeout={handleDragHoverTimeout}
          onSelect={handleSelect}
          onMultiSelect={handleMultiSelect}
          onItemMouseOver={handleItemMouseOver}
          onItemMouseLeave={handleItemMouseLeave}
          {...(flags['base-tf-ui-stack-reorder'] && {
            onReorder: handleReorder
          })}>
          {isObjectSelected && (
            <div slot="top-control" className="flex">
              <ActionButton
                size={buttonSize}
                quiet
                onClick={() => handleMoveSelectedObjects('up-one')}>
                <Icon slot="icon">
                  <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
                </Icon>
                <Tooltip selfManaged placement="top">
                  {t('scene:layers:move:up')}
                </Tooltip>
              </ActionButton>
              <ActionButton
                size={buttonSize}
                quiet
                onClick={() => handleMoveSelectedObjects('down-one')}>
                <Icon slot="icon">
                  <ArrowIcon style={{ transform: 'rotate(270deg)' }} />
                </Icon>
                <Tooltip selfManaged placement="top">
                  {t('scene:layers:move:down')}
                </Tooltip>
              </ActionButton>
            </div>
          )}

          <ActionMenu
            slot="bottom-control"
            quiet
            size={buttonSize}
            placement="bottom-end">
            <Icon slot="icon">
              <MoreIcon />
            </Icon>
            <MenuItem onClick={() => setShowSceneNavigator(false)}>
              {t('scene:layers:hideLayerWidget')}
            </MenuItem>
          </ActionMenu>
        </SceneNavigationUI>

        <CursorOverlay />
      </div>
    </>
  )
}

export default SceneNavigation
