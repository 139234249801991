import { FC, ReactNode, useContext, useRef } from 'react'
import Head from 'next/head'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Divider, ProgressCircle, Theme } from 'ui'

import cn from 'classnames'
import styles from '@styles/components/Studio.module.scss'

import { useProjectState } from '@hooks/useProject'
import { useFirefly } from '@hooks/useFirefly'
import { AspectRatio } from '@store/slices/fireflySlice'
import { useMaxSize } from '@hooks/useMaxSize'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useIsMobile } from '@hooks/useIsMobile'
import { useIsExportingVideo } from './useIsExportingVideo'
import { useInitializeUIPreference } from './useInitializeUIPreference'

import SyncInterface from '@components/sync/SyncInterface'
import UserFollowingIndicator from '@components/sync/UserFollowingIndicator'
import FrameDimension from '@components/propertiesPanel/FrameDimension'
import FireflyPreview from '@components/fireflyPanel/FireflyPreview'
import RightRail from '@components/rightRail/RightRail'
import LeftRail from '@components/leftRail/LeftRail'
import VideoExportDialog from '@components/share/VideoExportDialog'
import ErrorDialog from '@components/studio/ErrorDialog'
import RefreshDialog from './RefreshDialog'
import FireflyContextualMenu from '@components/fireflyPanel/FireflyContextualMenu'
import ContextBar from '@components/contextBar/ContextBar'
import MobileWarning from '@components/mobileWarning/mobileWarning'
import StudioAppBar from '@components/studioAppBar/StudioAppBar'
import TouchDeviceWarningDialog from './TouchDeviceWarningDialog'
import WindowsGPUConfigInstructionDialog from './WindowsGPUConfigInstructionDialog'
import SceneNavigation from '@components/SceneNavigation/SceneNavigation'
import Snapshot from '@components/snapshot/Snapshot'
import OrbitControls from '@components/propertiesPanel/OrbitControls'
import FloatingEditShapeToggle from '@components/propertiesPanel/FloatingEditShapeToggle'
import FireflyOnboardingDialog from '@components/fireflyPanel/FireflyOnboardingDialog'
import FireflyUploadImagesRightsDialog from '@components/fireflyPanel/FireflyUploadImagesRightsDialog'
import FireflyEmptySceneDialog from '@components/fireflyPanel/FireflyEmptySceneDialog'
import SettingsDialog from '@components/settingsDialog/SettingsDialog'
import FireflyFreeLimitedTimeToast from '@components/fireflyPanel/FireflyFreeLimitedTimeToast'
import SaveBackgroundDialog from '@components/library/SaveBackgroundDialog'
import SaveMaterialDialog from '@components/library/SaveMaterialDialog'
import SceneLayersPanel from '@components/SceneLayers/SceneLayersPanel'
import ChatPanel from '@components/chatPanel/ChatPanel'
import ChimePanel from '@components/chime/ChimePanel'
import CommunityPanel from '@components/communityPanel/CommunityPanel'
import FireflyPanel from '@components/fireflyPanel/FireflyPanel'
import LibraryPanel from '@components/library/LibraryPanel'
import PlayerPanel from '@components/playerPanel/PlayerPanel'
import PropertiesPanel from '@components/propertiesPanel/PropertiesPanel'
import StylesPanel from '@components/styles/StylesPanel'
import ViewsPanel from '@components/views/ViewsPanel'
import FireflyToSDialog from '@components/fireflyPanel/FireflyToSDialog'
import ProjectShareDialog from '@components/share/ProjectShareDialog'
import ProjectSharedToast from '@components/share/ProjectSharedToast'
import { PanelKeys, StudioPanel } from '@store/slices/projectSlice'
import StylesActionMenu from '@components/styles/StylesActionMenu'
import EnvironmentActionMenu from '@components/propertiesPanel/EnvironmentActionMenu'
import { CanvasContainer } from './CanvasContainer'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { AddObjectRail } from '@components/addObjectRail/AddObjectRail'
import { ToNeoDialog } from '@components/toNeo/ToNeoDialog'
import { LegalNoticesDialog } from '@components/legalNoticesDialog/LegalNoticesDialog'
import { ToNeoLoadingScreen } from '@components/toNeo/ToNeoLoadingScreen'
import { FireflyFreeUserDialog } from '@components/fireflyPanel/FireflyFreeUserDialog'
import { useAccessProfileState } from '@hooks/useAccessProfile'
import { FireflyUnavailableNotificationBar } from '@components/fireflyPanel/FireflyUnavailableNotificationBar'
import FireflyPaywallToast from '@components/fireflyPanel/FireflyPaywallToast'
import { LocaleContext } from '@concerns/i18n/components/LocaleProvider'
import { SVGImportErrorToast } from '@components/studioAppBar/SVGImportErrorToast'
import { useTranslations } from 'use-intl'
import { useStudioLoadingSpinner } from '@hooks/useStudioLoadingSpinner'
import UnifiedPaywallDialog from '@components/fireflyPanel/UnifiedPaywallDialog'

const AspectRatioMap: Record<AspectRatio, number> = {
  [AspectRatio.LANDSCAPE]: 4 / 3,
  [AspectRatio.PORTRAIT]: 3 / 4,
  [AspectRatio.SQUARE]: 1,
  [AspectRatio.WIDESCREEN]: 16 / 9
}

type Props = {
  uuid: string
}

const PanelMap: Record<PanelKeys, ReactNode> = {
  [StudioPanel.Properties]: <PropertiesPanel />,
  [StudioPanel.Library]: <LibraryPanel />,
  [StudioPanel.Chat]: <ChatPanel />,
  [StudioPanel.Player]: <PlayerPanel />,
  [StudioPanel.Community]: <CommunityPanel />,
  [StudioPanel.Firefly]: <FireflyPanel />,
  [StudioPanel.Meet]: <ChimePanel />,
  [StudioPanel.Views]: <ViewsPanel />,
  [StudioPanel.Layers]: <SceneLayersPanel />,
  [StudioPanel.Style]: <StylesPanel />
}

export const STUDIO_CONTAINER_ID = 'studio-container'

const Studio: FC<Props> = ({ uuid }) => {
  const flags = useFlags()

  const engineState = useSceneState('engineState')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )
  const { startVideoRecording } = useSceneActions()

  const name = useProjectState('name')
  const fireflyCommercializationFlowMode =
    flags['base-tf-firefly-commercialization-flow']
  const showFireflyOnboardingDialog = flags['base-tf-firefly-onboarding-dialog']
  const showContextBar = useProjectState('showContextBar')
  const showFireflyPopover = useProjectState('showFireflyPopover')
  const showOrbitControls = useProjectState('showOrbitControls')
  const fireflyFulfillableItemCreditModel = useAccessProfileState(
    'fireflyFulfillableItemCreditModel'
  )
  const showSceneNavigator = useProjectState('showSceneNavigator')
  const openedPanel = useProjectState('openedPanel')
  const isDraggingContextBar = useProjectState('isDraggingContextBar')
  const colorPreference = useProjectState('colorPreference')
  const sizePreference = useProjectState('sizePreference')

  const { aspectRatio, isSceneRealisticOrVectorArt, showPaywall } = useFirefly()

  useInitializeUIPreference()

  const isMobile = useIsMobile()

  const isDeviceCompatible = !isMobile
  const t = useTranslations()
  const mainSectionRef = useRef<HTMLDivElement>(null)

  const mainSectionItemSize = useMaxSize(
    showFireflyPopover ? mainSectionRef.current : null,
    AspectRatioMap[aspectRatio]
  )
  const mainSectionItemStyle =
    showFireflyPopover && mainSectionItemSize ? mainSectionItemSize : undefined

  const { showStudioLoadingSpinner } = useStudioLoadingSpinner()

  const isExportingVideo = useIsExportingVideo()
  const { isDefaultEnvironment, isSceneToImageEnvironment, environment } =
    useStudioEnvironment()

  const documentTitle = isSceneToImageEnvironment
    ? t('studio:document:title:scene-to-image')
    : name
    ? `${name} | ${t('studio:document:title:default')}`
    : `${t('studio:document:title:default')}`

  const showEnvironmentMenu = !showFireflyPopover

  const { activeLocale } = useContext(LocaleContext)

  // Include a check for the environment in the clause because otherwise this component gets rendered before the environment is set, but the environment is required so that the correct version of the warning + the correct copy is displayed
  if (!isDeviceCompatible && environment) {
    return (
      <Theme lang={activeLocale} color={colorPreference} system="express">
        <StudioAppBar />
        <MobileWarning />
      </Theme>
    )
  }

  return (
    <>
      <Head>
        <title>{documentTitle}</title>
      </Head>
      <Theme
        className={styles[`size-${sizePreference}`]}
        scale="medium"
        lang={activeLocale}
        color={colorPreference}
        system="spectrum"
      >
        <ToNeoLoadingScreen />
        <RefreshDialog />
        <ErrorDialog isStudioPage />
        <WindowsGPUConfigInstructionDialog />
        <TouchDeviceWarningDialog />
        <VideoExportDialog />
        {showFireflyOnboardingDialog && <FireflyOnboardingDialog />}
        {fireflyCommercializationFlowMode &&
          fireflyFulfillableItemCreditModel === 'FREE-EXHAUSTABLE' &&
          showFireflyPopover && <FireflyFreeUserDialog />}
        <FireflyUploadImagesRightsDialog />
        <FireflyEmptySceneDialog />
        <FireflyToSDialog />
        <SettingsDialog context="studio" />
        <SaveBackgroundDialog />
        <ProjectShareDialog />
        <SaveMaterialDialog />
        <ToNeoDialog />
        <LegalNoticesDialog />
        {fireflyCommercializationFlowMode && showPaywall !== 'none' && (
          <UnifiedPaywallDialog />
        )}

        <div
          id={STUDIO_CONTAINER_ID}
          data-size-preference={sizePreference}
          className={cn(styles['container'], {
            [styles['minimal-layout']]: canvasAnimationStartedForRecording,
            'no-pointer-events': isDraggingContextBar
          })}
          onTransitionEnd={e => {
            if (
              e.target === e.currentTarget &&
              canvasAnimationStartedForRecording &&
              e.propertyName === 'grid-template-columns'
            ) {
              startVideoRecording()
            }
          }}
        >
          <div className={styles['notification-bar']}>
            {fireflyCommercializationFlowMode &&
              fireflyFulfillableItemCreditModel === 'UNAVAILABLE' &&
              showFireflyPopover && <FireflyUnavailableNotificationBar />}
          </div>
          <StudioAppBar className={styles['app-bar']} />
          <div className={styles['left-rail']}>
            <LeftRail />
          </div>
          <div className={styles['add-object-rail']}>
            <AddObjectRail />
          </div>

          <div
            className={cn(styles['main'], {
              [styles['main-firefly-preview']]: showFireflyPopover
            })}
          >
            {flags['base-tf-firefly-limited-time-toast'] && (
              <FireflyFreeLimitedTimeToast />
            )}

            <SVGImportErrorToast />
            <ProjectSharedToast />

            {showStudioLoadingSpinner && (
              <div className={styles['progress-circle-container']}>
                <ProgressCircle size="l" indeterminate />
              </div>
            )}

            {flags['base-pf-ui-firefly'] &&
              isSceneRealisticOrVectorArt &&
              (showFireflyPopover || !isExportingVideo) &&
              !canvasAnimationStartedForRecording && (
                <>
                  <FireflyContextualMenu />
                  {fireflyCommercializationFlowMode && <FireflyPaywallToast />}
                </>
              )}

            <ContextBar
              show={
                !canvasAnimationStartedForRecording &&
                !isExportingVideo &&
                !showFireflyPopover &&
                showContextBar &&
                engineState === 'INITIALIZED_AND_DOCUMENT_LOADED' &&
                isDefaultEnvironment
              }
            />

            {!canvasAnimationStartedForRecording &&
              !isExportingVideo &&
              !showStudioLoadingSpinner &&
              !showFireflyPopover && (
                <div className={styles['floating-top-left-action-buttons']}>
                  {flags['base-pf-ui-snapshot'] && isDefaultEnvironment && (
                    <Snapshot />
                  )}

                  {(flags['base-pf-ui-styles-floating-menu'] ||
                    flags['base-pf-ui-environment-floating-menu']) && (
                    <div className={styles['styles-environment-action-menus']}>
                      {flags['base-pf-ui-styles-floating-menu'] && (
                        <StylesActionMenu />
                      )}
                      {flags['base-pf-ui-styles-floating-menu'] &&
                        flags['base-pf-ui-environment-floating-menu'] &&
                        showEnvironmentMenu && (
                          <Divider
                            className="vertical-divider"
                            size="m"
                            vertical
                          />
                        )}
                      {flags['base-pf-ui-environment-floating-menu'] &&
                        showEnvironmentMenu && <EnvironmentActionMenu />}
                    </div>
                  )}
                </div>
              )}

            {!showFireflyPopover &&
              flags['base-tf-ui-scene-navigation'] &&
              !isExportingVideo &&
              showSceneNavigator &&
              isDefaultEnvironment && <SceneNavigation />}

            {!showFireflyPopover &&
              flags['base-tf-ui-camera-orbit'] &&
              showOrbitControls &&
              !canvasAnimationStartedForRecording &&
              engineState === 'INITIALIZED_AND_DOCUMENT_LOADED' && (
                <OrbitControls />
              )}

            <div
              className={cn({
                [styles['max-size']]: !showFireflyPopover,
                [styles['main-firefly-preview-section']]: showFireflyPopover,
                ['justify-end']: showFireflyPopover
              })}
              ref={mainSectionRef}
            >
              <CanvasContainer
                mainSectionItemStyle={mainSectionItemStyle!}
                uuid={uuid}
              >
                {flags['base-pf-collaboration'] && (
                  <>
                    <UserFollowingIndicator />
                    <SyncInterface />
                  </>
                )}
                {flags['base-pf-ui-frame-dimension'] &&
                  !showStudioLoadingSpinner && <FrameDimension />}
              </CanvasContainer>
            </div>

            {showFireflyPopover && (
              <div className={styles['main-firefly-preview-section']}>
                <div
                  className={cn('position-relative', styles['border-radius'])}
                  style={mainSectionItemStyle}
                >
                  <FireflyPreview />
                </div>
              </div>
            )}
          </div>
          <div className={styles['panels']}>
            {openedPanel && (
              <div className={styles['panels-container']}>
                {PanelMap[openedPanel]}
              </div>
            )}
          </div>

          <div className={styles['right-rail']}>
            <RightRail />
          </div>
        </div>
      </Theme>
    </>
  )
}

export default Studio
